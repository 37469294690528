import {FC} from 'react'
import {Check} from 'jbc-front/components/icons'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import styles from './AlertList.scss'

interface CheckCompleteMenuItemProps {
  isCompleted: boolean
}

export const CheckCompleteMenuItem: FC<CheckCompleteMenuItemProps> = ({isCompleted}) => {
  return (
    <span>
      {isCompleted ? (
        <div className={styles.completed}>
          <Check />
          <Spacer direction="x" size={5} />
          <span>完了済み</span>
        </div>
      ) : (
        <div className={styles.uncompleted}>
          <span className={styles.circle}></span>
          <Spacer direction="x" size={5} />
          <span>完了にする</span>
        </div>
      )}
    </span>
  )
}
