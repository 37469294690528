import {FC} from 'react'
import Paginator from 'jbc-front/components/Paginator'
import {SearchResult} from 'jbc-front/components/SearchForm'

import styles from './PaginatorWithResult.scss'

type PaginatorWithResultProps = {
  limit: number
  count: number
  page: number
  isLoading: boolean
  isEditing: boolean
  onClick: (page: number) => void
}
export const PaginatorWithResult: FC<PaginatorWithResultProps> = ({
  limit,
  count,
  page,
  isLoading,
  isEditing,
  onClick,
}) => (
  <div>
    {count > 0 ? (
      <div className={styles.paginatorWithResult}>
        {!isEditing && (
          <Paginator current={page} totalResult={count} rowsPerPage={limit} pageLinks={7} onClick={onClick} />
        )}
        <SearchResult total={count} page={page} limit={limit} isLoading={isLoading} className={styles.resultRight} />
      </div>
    ) : null}
  </div>
)
