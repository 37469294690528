import {FC, useState} from 'react'
import {ApolloQueryResult} from '@apollo/client'
import {useQuery} from 'hooks/graphql'
import {useDispatch} from 'react-redux'

import {asyncError} from 'store/actions/asyncError'
import {Presenter} from './Presenter'
import {ALERT_TYPES, UPDATE_ALERT_TYPE_ORDINALS} from './query'
import type {AlertTypesQueryResult, AlertTypesQueryVariables, UpdateAlertTypeOrdinalsQueryResult} from './query'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export type Refetch = () => Promise<ApolloQueryResult<AlertTypesQueryResult>>

export const Show: FC = () => {
  const dispatch = useDispatch()

  const [variables, setVariables] = useState<AlertTypesQueryVariables>({
    page: 1,
    per: 10,
  })

  const {data, refetch, loading} = useQuery<AlertTypesQueryResult>(ALERT_TYPES, {
    variables,
    fetchPolicy: 'network-only',
  })

  const [updateAlertTypeOrdinals] = useMutation<UpdateAlertTypeOrdinalsQueryResult>(UPDATE_ALERT_TYPE_ORDINALS)

  const handleSaveOrdinals = async (idList: number[], ordinalList: number[]) => {
    try {
      await updateAlertTypeOrdinals({
        variables: {
          input: {
            ids: idList,
            ordinals: ordinalList,
          },
        },
        fetchPolicy: 'network-only',
      })
      await refetch()
    } catch (error) {
      dispatch(asyncError(error))
    }
  }

  return (
    <Presenter
      totalCount={data?.alertTypes.totalCount ?? 0}
      alertTypes={data?.alertTypes?.list ?? []}
      refetch={refetch}
      variables={variables}
      setVariables={setVariables}
      handleSaveOrdinals={handleSaveOrdinals}
      loading={loading}
    />
  )
}
