import {SortableElement} from 'react-sortable-hoc'
import classnames from 'classnames'

import {Table} from 'components/ui/Table'
import {EditColumn} from './EditColumn'
import {DeleteColumn} from './DeleteColumn'

import {AlertCustomizeCheckCircle as CheckCircle} from 'jbc-front/components/icons'
import {Drugging} from 'jbc-front/components/icons'

import type {Refetch} from '../../index'
import type {AlertType, AlertTypeNotification} from '../../query'

import styles from './List.scss'

interface RowProps {
  alert: AlertType
  index: number
  refetch: Refetch
}

interface SortableRowProps {
  alert: AlertType
  refetch: Refetch
}

const alertNotificationAdmin = (alertType: AlertType) => {
  return alertType.alertTypeNotifications.find(
    (notification: AlertTypeNotification) => notification.destination === 'admin'
  )
}

const alertNotificationEmployee = (alertType: AlertType) => {
  return alertType.alertTypeNotifications.find(
    (notification: AlertTypeNotification) => notification.destination === 'employee'
  )
}

const toColor = (int: number): string => (+int ? `#${(+int).toString(16).padStart(6, '0')}` : '#333333')

export const Row = ({alert, index, refetch}: RowProps) => {
  return (
    <Table.Tr key={alert.id}>
      <Table.Td className={alert.active ? '' : styles.inactive}>{index}</Table.Td>
      <Table.Td className={alert.active ? '' : styles.inactive}>
        <span className={styles.circle} style={{backgroundColor: toColor(alert.color)}} />
      </Table.Td>
      <Table.Td className={alert.active ? '' : styles.inactive}>{alert.name}</Table.Td>
      <Table.Td className={alert.active ? '' : styles.inactive}>
        <div className={styles.descriptionBox}>{alert.description}</div>
      </Table.Td>
      <Table.Td className={alert.active ? '' : styles.inactive}>{alert.alertDateCondition}</Table.Td>
      <Table.Td className={alert.active ? '' : styles.inactive}>
        {alertNotificationAdmin(alert)?.active ? <CheckCircle /> : ''}
      </Table.Td>
      <Table.Td className={alert.active ? '' : styles.inactive}>
        {alertNotificationEmployee(alert)?.active ? <CheckCircle /> : ''}
      </Table.Td>
      <Table.Td>
        <EditColumn alert={alert} />
      </Table.Td>
      <Table.Td>
        <DeleteColumn alert={alert} refetch={refetch} />
      </Table.Td>
    </Table.Tr>
  )
}

export const SortableRow = SortableElement(({alert, refetch}: SortableRowProps) => (
  <Table.Tr key={alert.id} className={classnames(styles.sortableRow, alert.active ? '' : styles.inactive)}>
    <Table.Td className={styles.numberColumn}>
      <Drugging />
    </Table.Td>
    <Table.Td className={styles.colorColumn}>
      <span className={styles.circle} style={{backgroundColor: toColor(alert.color)}} />
    </Table.Td>
    <Table.Td className={styles.nameColumn}>{alert.name}</Table.Td>
    <Table.Td className={styles.explanationColumn}>
      <div className={styles.descriptionBox}>{alert.description}</div>
    </Table.Td>
    <Table.Td className={styles.baseDateColumn}>{alert.alertDateCondition}</Table.Td>
    <Table.Td className={styles.adminMailColumn}>
      {alertNotificationAdmin(alert)?.active ? <CheckCircle /> : ''}
    </Table.Td>
    <Table.Td className={styles.employeeMailColumn}>
      {alertNotificationEmployee(alert)?.active ? <CheckCircle /> : ''}
    </Table.Td>
    <Table.Td className={styles.editColumn}>
      <EditColumn alert={alert} />
    </Table.Td>
    <Table.Td className={styles.deleteColumn}>
      <DeleteColumn alert={alert} refetch={refetch} />
    </Table.Td>
  </Table.Tr>
))
