import {FC, useState} from 'react'
import {useQuery} from 'hooks/graphql'
import {useDispatch} from 'react-redux'
import ActionButton from 'jbc-front/components/ActionButton'
import {AlertNotification} from 'jbc-front/components/icons'
import {useRightExpansionContainer} from 'components/feature/RightExpansionContainer'
import {LoadingPage} from 'components/ui/LoadingPage'
import {Check} from 'jbc-front/components/icons'
import {asyncError} from 'store/actions/asyncError'
import {
  AlertStatus,
  QueryVariables,
  QueryResult,
  HasActiveQueryVariables,
  HasActiveQueryResult,
  HAS_ACTIVE_EMPLOYEE_ALERT,
  EMPLOYEE_ALERT,
  COMPLETE_EMPLOYEE_ALERT,
} from './query'
import styles from './EmployeeAlerts.scss'
import classnames from 'classnames'
import {dateFormat} from 'libs/formatter'

// @ts-ignore
import {useMutation} from 'components/Graphql'

type SideContentProps = {
  id: string
  refetchHasActive: () => void
}

type EmployeeAlertsProps = {
  id: string
}

const toColor = (int: number): string => (+int ? `#${(+int).toString(16).padStart(6, '0')}` : '#333333')

const SideContent: FC<SideContentProps> = ({id, refetchHasActive}) => {
  const dispatch = useDispatch()
  const [queryVariables, setQueryVariables] = useState<QueryVariables>({employeeId: id, alertStatus: 'in_progress'})
  const {
    loading: loading,
    data: queryResult,
    refetch,
  } = useQuery<QueryResult, QueryVariables>(EMPLOYEE_ALERT, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
  })
  const [completeEmployeeAlert] = useMutation(COMPLETE_EMPLOYEE_ALERT)

  const handleClickTab = (nextAlertStatus: AlertStatus | null) => {
    setQueryVariables((prevState) => ({
      ...prevState,
      alertStatus: nextAlertStatus,
    }))
  }

  const handleClickCircle = async (alertId: string) => {
    try {
      await completeEmployeeAlert({variables: {input: {id: alertId}}})
      refetch()
      refetchHasActive()
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <div className={styles.sideContent}>
      <div className={styles.tabContainer}>
        <div
          className={classnames(!queryVariables.alertStatus ? styles.activeTab : styles.tab)}
          onClick={() => handleClickTab(null)}
        >
          すべて
        </div>
        <div
          className={classnames(queryVariables.alertStatus === 'in_progress' ? styles.activeTab : styles.tab)}
          onClick={() => handleClickTab('in_progress')}
        >
          進行中
        </div>
        <div
          className={classnames(queryVariables.alertStatus === 'completed' ? styles.activeTab : styles.tab)}
          onClick={() => handleClickTab('completed')}
        >
          完了
        </div>
      </div>
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          {!queryResult || queryResult.employeeAlert.length === 0 ? (
            <p className={styles.noAlertsMessage}>アラートはありません</p>
          ) : (
            queryResult.employeeAlert.map((alert) => (
              <div
                key={alert.id}
                className={classnames(styles.alertContainer, {
                  [styles.completedFont]: alert.alertStatus === 'completed',
                })}
              >
                <div className={styles.firstRowContainer}>
                  <span className={styles.alertColor} style={{backgroundColor: toColor(alert.alertType.color)}} />
                  <p className={styles.alertName}>{alert.alertType.name}</p>
                  <span className={styles.alertStatusWrapper}>
                    {alert.alertStatus === 'completed' ? (
                      <Check className={styles.check} />
                    ) : (
                      <div className={styles.completionWrapper}>
                        <span className={styles.completionCircle} onClick={() => handleClickCircle(alert.id)} />
                        <span className={styles.completionConfirmation}>完了にする</span>
                      </div>
                    )}
                  </span>
                </div>
                <p>（基準日：{dateFormat(alert.baseDate, 'seireki')}）</p>
                <p className={styles.alertDescription}>{alert.alertType.description}</p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export const EmployeeAlerts: FC<EmployeeAlertsProps> = ({id}) => {
  const {setContent} = useRightExpansionContainer()

  const {data: hasActive, refetch} = useQuery<HasActiveQueryResult, HasActiveQueryVariables>(
    HAS_ACTIVE_EMPLOYEE_ALERT,
    {
      fetchPolicy: 'network-only',
      variables: {employeeId: id},
    }
  )

  const handleClick = () => {
    const key = `employeeAlert-${id}`
    setContent(<SideContent id={id} refetchHasActive={refetch} />, key, 400)
  }

  return (
    <div className={styles.buttonContainer}>
      <ActionButton onClick={handleClick} className={styles.alertButton}>
        <AlertNotification className={styles.notificationIcon} />
        アラート表示
      </ActionButton>
      <span className={classnames(styles.badge, {[styles.visible]: hasActive?.hasActiveEmployeeAlert})}></span>
    </div>
  )
}
