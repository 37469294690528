import {FC} from 'react'
import classnames from 'classnames'
import {CheckCompleteMenu} from './CheckCompleteMenu'
import {CheckCompleteMenuItem} from './CheckCompleteMenuItem'
import {fullNameFormat} from 'libs/formatter'
import {Table} from 'components/ui/Table'
import {EmployeeAlert} from '../../query'
import styles from './AlertList.scss'

// @ts-ignore
import {toDateStr} from 'utils'

interface RowProps {
  alert: EmployeeAlert
  handleComplete: (alertId: string, isCompleted: boolean) => Promise<void>
}

const toColor = (int: number): string => (+int ? `#${(+int).toString(16).padStart(6, '0')}` : '#333333')

export const Row: FC<RowProps> = ({alert, handleComplete}) => {
  const fullName = fullNameFormat(alert.employee.lastName, alert.employee.firstName)
  const alertStart = toDateStr(alert.alertStart, 'YYYY年MM月DD日')
  const baseDate = toDateStr(alert.baseDate, 'YYYY年MM月DD日')
  const isCompleted = alert.alertStatus === 'completed'
  const completeStyle = isCompleted ? styles.completed : undefined

  const handleClick = () => {
    handleComplete(alert.id, isCompleted)
  }

  return (
    <Table.Tr className={classnames('table-hover', isCompleted ? styles.inactive : '')}>
      <Table.Td>
        <span className={styles.color} style={{backgroundColor: toColor(alert.alertType.color)}}></span>
      </Table.Td>

      <Table.Td>{alertStart}</Table.Td>

      <Table.Td>{alert.alertType.name}</Table.Td>

      <Table.Td>{baseDate}</Table.Td>

      <Table.Td>{alert.alertType.alertDateCondition}</Table.Td>

      <Table.Td>{alert.employee.staffCode}</Table.Td>

      <Table.Td>{fullName}</Table.Td>
      <Table.Td>
        <CheckCompleteMenu
          alert={alert}
          menuItems={[
            {label: <CheckCompleteMenuItem isCompleted={isCompleted} />, onClick: handleClick, style: completeStyle},
          ]}
        />
      </Table.Td>
    </Table.Tr>
  )
}
