import {FC} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {LoadingPage} from 'components/ui/LoadingPage'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import {
  AlertTypeInput,
  CustomEmployeeFieldGroupQuery,
  AlertTypeQuery,
  ALERT_TYPE,
  CUSTOM_EMPLOYEE_FIELDS,
} from './query'
import {convertFormDataToMutationAlertType, UPDATE_ALERT_TYPE} from './mutation'
import {AlertTypeEditForm} from './parts/AlertTypeEditForm'
import {Content} from 'components/layout/Content'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import useRouter from 'use-react-router'
import {useQuery} from 'hooks/graphql'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export const Presenter: FC = () => {
  const dispatch = useDispatch()
  const {
    match: {
      params: {id},
    },
  } = useRouter<{id: string}>()

  const {loading: CustomFieldQueryLoading, data: CustomFieldQueryResult} = useQuery<CustomEmployeeFieldGroupQuery>(
    CUSTOM_EMPLOYEE_FIELDS,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
  const {loading: alertTypeQueryLoading, data: alertTypeQueryResult} = useQuery<AlertTypeQuery>(ALERT_TYPE, {
    variables: {id: id},
    fetchPolicy: 'cache-and-network',
  })

  const [updateAlertType, {loading: mutationLoading}] = useMutation(UPDATE_ALERT_TYPE)

  const onSubmit = async (data: AlertTypeInput, isDestroy = false) => {
    try {
      await updateAlertType({variables: {input: convertFormDataToMutationAlertType(data, id, isDestroy)}})
      dispatch(push('/settings/alert_custom'))
      dispatch(notifySuccess('編集しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  if (CustomFieldQueryLoading || alertTypeQueryLoading) {
    return <LoadingPage />
  } else if (!alertTypeQueryResult?.alertType) {
    dispatch(notifyError('アラートが見つかりませんでした'))
    return <Redirect to="/settings/alert_custom" />
  }

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">アラートカスタマイズ</h1>
        <p className="m-title-main-note">従業員情報の項目にアラートの設定を行うことができます</p>
      </div>
      <Content size="xxl">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/alert_custom" className="l-breadcrumb-link">
            アラートカスタマイズ
          </Link>
          <span className="l-breadcrumb-here">編集</span>
        </div>
        <AlertTypeEditForm
          onSubmit={onSubmit}
          loading={mutationLoading}
          customFields={CustomFieldQueryResult?.customEmployeeFieldGroups ?? []}
          alertType={alertTypeQueryResult?.alertType}
        />
      </Content>
    </div>
  )
}
