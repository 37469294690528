import {Option} from 'jbc-front/components/presenters/form/Select'
import {BASIC_BASE_DATE_TYPE_VALUES, BASIC_BASE_DATE_TYPE_LABELS} from 'consts/alertCustom'

export const wrapperSectionStyle = {
  maxWidth: '100%',
  margin: 'inherit',
}

const dateUnitSelectOptions: Option[] = [
  {value: 'day', label: '日'},
  {value: 'month', label: 'ヶ月'},
  {value: 'year', label: '年'},
]

export const basicBaseDateTypeSelectOptions: Option[] = BASIC_BASE_DATE_TYPE_LABELS.map((label, index) => ({
  label: label,
  value: BASIC_BASE_DATE_TYPE_VALUES[index] as string,
}))

export const colors: number[] = [
  0x3498db, 0xffcb03, 0xf48080, 0x17b491, 0x7d3cb2, 0xfb8b4f, 0xe61345, 0x333333, 0xdddddd,
]

export const toColor = (int: number): string => (+int ? `#${(+int).toString(16).padStart(6, '0')}` : '#333333')

export const dateUnitOptionsFilter = (base_date_type: string): Option[] => {
  return base_date_type == 'birthday'
    ? dateUnitSelectOptions.filter((option) => option.value !== 'year')
    : dateUnitSelectOptions
}
