import {FC} from 'react'
import {Controller, Control} from 'react-hook-form'
import styles from './SearchForm.scss'
import {DateField} from 'jbc-front/components/presenters/form/DateField'
import {ErrorMessage} from 'jbc-front/components/presenters/form/ErrorMessage'
import moment from 'moment'
import {SearchAlertInput} from '../../query'
import {UseFormSetValue} from 'react-hook-form'

type DateRowProps = {
  header: string
  errorMessage: string | undefined
  names: ['alertStartStartOn', 'alertStartEndOn'] | ['baseDateStartOn', 'baseDateEndOn']
  control: Control<SearchAlertInput>
  autoSubmit: () => Promise<void>
  setValue: UseFormSetValue<SearchAlertInput>
}

const checkBeforeAndSetNewEndDate = (
  fieldName: 'alertStartStartOn' | 'baseDateStartOn',
  newValue: moment.Moment | string,
  currentValue: string,
  setValue: UseFormSetValue<SearchAlertInput>
) => {
  if (typeof newValue === 'string') {
    newValue = moment(newValue, 'YYYY/MM/DD')
  }
  const isBefore = (newValue as moment.Moment).isBefore(moment(currentValue, 'YYYY/MM/DD'))
  if (isBefore) {
    const tempDate = (newValue as moment.Moment).format('YYYY/MM/DD')
    const newEndDate = moment(tempDate, 'YYYY/MM/DD').add(3, 'months').subtract(1, 'days')
    if (fieldName === 'alertStartStartOn') {
      setValue('alertStartEndOn', newEndDate.format('YYYY/MM/DD'))
    } else if (fieldName === 'baseDateStartOn') {
      setValue('baseDateEndOn', newEndDate.format('YYYY/MM/DD'))
    }
  }
}

export const DateRow: FC<DateRowProps> = ({header, errorMessage, names, control, autoSubmit, setValue}) => {
  const onChangeDateField = (field: any, value: moment.Moment | string) => {
    if (typeof value === 'string') {
      field.onChange(value)
    } else {
      field.onChange(value.format('YYYY/MM/DD'))
    }
    autoSubmit()
  }

  return (
    <div className={styles.detailsRow}>
      <div className={styles.detailsHeader}>{header}</div>
      <div className={styles.detailsBody}>
        <div className={styles.detailsBodyItems}>
          <div className={styles.alertDateSelect}>
            <Controller
              name={names[0]}
              control={control}
              render={({field}) => (
                <DateField
                  value={field.value}
                  inputProps={{isError: !!errorMessage}}
                  onChange={(value: moment.Moment | string) => {
                    if (['alertStartStartOn', 'baseDateStartOn'].includes(field.name) && moment.isMoment(value)) {
                      checkBeforeAndSetNewEndDate(field.name, value, field.value, setValue)
                    }
                    onChangeDateField(field, value)
                  }}
                />
              )}
            />
          </div>
          <span className={styles.detailsComplementText}>から</span>
          <div className={styles.alertDateSelect}>
            <Controller
              name={names[1]}
              control={control}
              render={({field}) => (
                <DateField
                  value={field.value}
                  inputProps={{isError: !!errorMessage}}
                  onChange={(value: moment.Moment | string) => onChangeDateField(field, value)}
                />
              )}
            />
          </div>
          <span className={styles.detailsComplementText}>の間</span>
        </div>
        <div className={styles.detailsBodyErrors}>{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}</div>
      </div>
    </div>
  )
}
