import {FC} from 'react'
import {Table} from 'components/ui/Table'
import {EmployeeAlert, SortColumn, Order, COMPLETE_EMPLOYEE_ALERT} from '../../query'
import {Row} from './Row'
import {useDispatch} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface AlertListProps {
  alerts: EmployeeAlert[]
  onSort: (column: SortColumn) => void
  order: Order
  sortColumn: SortColumn
  handleRefetch: () => void
}

export const AlertList: FC<AlertListProps> = ({alerts, onSort, order, sortColumn, handleRefetch}) => {
  const dispatch = useDispatch()
  const [completeEmployeeAlert] = useMutation(COMPLETE_EMPLOYEE_ALERT)

  const handleComplete = async (alertId: string, isCompleted: boolean) => {
    if (!isCompleted) {
      try {
        await completeEmployeeAlert({variables: {input: {id: alertId}}})
        handleRefetch()
      } catch (err) {
        dispatch(asyncError(err))
      }
    }
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.SortableTh onClick={() => onSort('color')} order={order} active={sortColumn == 'color'} width="5%">
            カラー
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('alert_start')}
            order={order}
            active={sortColumn == 'alert_start'}
            width="12%"
          >
            通知日
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('alert_type_name')}
            order={order}
            active={sortColumn == 'alert_type_name'}
            width="25%"
          >
            アラート名
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('base_date')}
            order={order}
            active={sortColumn == 'base_date'}
            width="12%"
          >
            基準日
          </Table.SortableTh>

          <Table.Th width="11%">通知基準</Table.Th>

          <Table.SortableTh
            onClick={() => onSort('staff_code')}
            order={order}
            active={sortColumn == 'staff_code'}
            width="10%"
          >
            スタッフコード
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('employee_name')}
            order={order}
            active={sortColumn == 'employee_name'}
            width="15%"
          >
            氏名
          </Table.SortableTh>

          <Table.Th width="11%">ステータス</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {alerts.length > 0 ? (
          alerts.map((alert) => (
            <Row key={`alert-list-row-${alert.id}`} alert={alert} handleComplete={handleComplete} />
          ))
        ) : (
          <tr>
            <td colSpan={9}>アラートがありません</td>
          </tr>
        )}
      </Table.Tbody>
    </Table>
  )
}
