import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {CsvDownloadSearchParams, TargetType, EncodingType} from 'types/api/exportAlertsCsv/exportAlertsCsv'
import {useDispatch} from 'react-redux'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

type Params = {
  target: TargetType
  encoding: EncodingType
  search: CsvDownloadSearchParams
}

export const useAsyncDownloadAlertsCsv = () => {
  const authAxios = useAuthAxios()
  const dispatch = useDispatch()

  return async (params: Params) => {
    const alertQuery = params.target === 'search' ? params.search : null
    const requestParams = {
      encoding: params.encoding,
      alert_query: alertQuery,
    }
    const path = `/api/export_alerts_csv_request`

    try {
      await authAxios.post(path, requestParams)
      dispatch(notifySuccess('アラートデータはメールにてお送りします'))
    } catch (error) {
      dispatch(asyncError(error))
    }
  }
}
