import * as yup from 'yup'
import {Option} from 'jbc-front/components/presenters/form/Select'

const DEFAULT_MIN_VALUE = 0
const DEFAULT_MAX_VALUE = 999
const BIRTHDAY_MAX_DAYS = 364
const BIRTHDAY_MAX_MONTHS = 11

const dateUnitCountLabel = (dateUnitValue: string): string => {
  switch (dateUnitValue) {
    case 'day':
      return '日数'
    case 'month':
      return '月数'
    case 'year':
      return '年数'
    default:
      return '日数'
  }
}

const containsNonDigit = (value: string): boolean => {
  return /\D/.test(value)
}

const countHtmlTextLength = (htmlStr: string) => {
  const htmlStrWithNewline = htmlStr.replace(/<br\s*\/?>/gi, '\n')
  const parser = new DOMParser()
  const html = parser.parseFromString(htmlStrWithNewline, 'text/html')
  const text = html.body.innerText
  return text.length
}

export const schema = yup.object().shape({
  name: yup
    .string()
    .test('name-test', 'アラート名を入力してください', (value) => {
      if (value?.trim() !== '') return true
      return false
    })
    .max(20, 'アラート名は20文字以下で入力してください'),
  description: yup.string().max(255, '説明は255文字以下で入力してください'),
  days: yup.mixed().test('days-test', '日数に適切な数値を入力してください', function (value) {
    const baseDateType = this.parent.baseDateType.value
    const dateUnit = this.parent.dateUnit
    const countLabel = dateUnitCountLabel(dateUnit)

    if (value === undefined || value === null || value === '') {
      return this.createError({message: `${countLabel}を入力してください`})
    }

    let maxValue = DEFAULT_MAX_VALUE
    if (baseDateType === 'birthday' && dateUnit === 'day') {
      maxValue = BIRTHDAY_MAX_DAYS
    } else if (baseDateType === 'birthday' && dateUnit === 'month') {
      maxValue = BIRTHDAY_MAX_MONTHS
    }

    if (containsNonDigit(value)) {
      return this.createError({
        message: `${countLabel}は${DEFAULT_MIN_VALUE}以上${maxValue}以下の整数を入力してください`,
      })
    }

    const valueNumber = Number(value)
    if (valueNumber < DEFAULT_MIN_VALUE || valueNumber > maxValue) {
      return this.createError({
        message: `${countLabel}は${DEFAULT_MIN_VALUE}以上${maxValue}以下の整数を入力してください`,
      })
    }

    return true
  }),
  offset: yup.mixed().test('offset-test', '年齢または勤続年数を入力してください', function (value) {
    const baseDateType = this.parent.baseDateType.value
    if (baseDateType !== 'age' && baseDateType !== 'service_years') {
      return true
    }
    const baseDateTypeLabel = this.parent.baseDateType.label

    if (value === undefined || value === null || value === '') {
      return this.createError({message: `${baseDateTypeLabel}を入力してください`})
    }

    if (containsNonDigit(value)) {
      return this.createError({
        message: `${baseDateTypeLabel}は${DEFAULT_MIN_VALUE}以上${DEFAULT_MAX_VALUE}以下の整数を入力してください`,
      })
    }

    const valueNumber = Number(value)
    if (valueNumber < DEFAULT_MIN_VALUE || valueNumber > DEFAULT_MAX_VALUE) {
      return this.createError({
        message: `${baseDateTypeLabel}は${DEFAULT_MIN_VALUE}以上${DEFAULT_MAX_VALUE}以下の整数を入力してください`,
      })
    }

    return true
  }),
  dateUnit: yup.string().when('baseDateType', {
    is: (value: Option) => value.value === 'birthday',
    then: yup.string().notOneOf(['year'], '年は選択できません'),
  }),
  adminMailSubject: yup.string().when('adminMailActive', {
    is: true,
    then: yup
      .string()
      .test('subject-test', '件名を入力してください', (value) => {
        if (value?.trim() !== '') return true
        return false
      })
      .max(255, '件名は255文字以下で入力してください'),
  }),
  adminMailBody: yup.string().when('adminMailActive', {
    is: true,
    then: yup
      .string()
      .required('本文を入力してください')
      .test('maxLength', '本文は1000文字以下で入力してください', (value) => {
        if (!value) return true
        if (countHtmlTextLength(value) > 1000) return false
        return true
      }),
  }),
  employeeMailSubject: yup.string().when('employeeMailActive', {
    is: true,
    then: yup
      .string()
      .test('subject-test', '件名を入力してください', (value) => {
        if (value?.trim() !== '') return true
        return false
      })
      .max(255, '件名は255文字以下で入力してください'),
  }),
  employeeMailBody: yup.string().when('employeeMailActive', {
    is: true,
    then: yup
      .string()
      .required('本文を入力してください')
      .test('maxLength', '本文は1000文字以下で入力してください', (value) => {
        if (!value) return true
        if (countHtmlTextLength(value) > 1000) return false
        return true
      }),
  }),
})
