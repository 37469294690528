import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import {Link} from 'react-router-dom'
import api from 'api'
import styles from 'dashboard/dashboard.scss'
import {recordDisplay} from 'utils'
import moment from 'moment'
import ListGroup from 'jbc-front/components/ListGroup'
import {CustomAlerts} from 'dashboard/CustomAlerts/CustomAlerts'
import classnames from 'classnames'

class Alerts extends Component {
  componentDidMount() {
    const {dispatch, token} = this.props
    const authedApi = api.createWithAuth(token)
    dispatch(
      actionCreators.fetchData(
        'alerts',
        Promise.all([
          authedApi.alerts.contractAlerts(),
          authedApi.alerts.visaAlerts(),
          authedApi.alerts.commentAlerts(),
          authedApi.alerts.customAlertsCount({alert_query: {alert_status: 'in_progress'}}),
        ]).then(([{data: contractAlerts}, {data: visaAlerts}, {data: commentAlerts}, {data: customAlertsCount}]) => ({
          contractAlerts,
          visaAlerts,
          commentAlerts,
          customAlertsCount,
        }))
      )
    )
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('alerts'))
  }

  render() {
    const {
      alerts: {
        contractAlerts = [],
        visaAlerts = [],
        commentAlerts: {comment_alerts_count, comments = []} = {},
        customAlertsCount = 0,
      },
    } = this.props
    const DISPLAY_COMMENT_ALERT_COUNT = 5
    const extraCommentAlertsCount = comment_alerts_count - DISPLAY_COMMENT_ALERT_COUNT

    return (
      (contractAlerts.length > 0 || visaAlerts.length > 0 || comments.length > 0 || customAlertsCount > 0) && (
        <article className={styles.alerts}>
          <div className={styles.card}>
            <div className={classnames(styles.card_header, styles.insideBtnCardHeader)}>
              <ListGroup.Title>
                アラート
                <div className={styles.more}>
                  <Link to="/alerts" className={styles.more_btn}>
                    すべて見る
                  </Link>
                </div>
              </ListGroup.Title>
            </div>
            <div className={styles.card_body}>
              <div>
                {contractAlerts.length > 0 && (
                  <React.Fragment>
                    <h4>契約更新が必要な従業員がいます</h4>
                    <ul>
                      {contractAlerts.map((employee) => (
                        <li className={styles.alert} key={employee.id}>
                          <Link
                            to={`/employees/${employee.id}/edit#EmploymentInfomation`}
                            className={styles.alert_link}
                          >
                            {recordDisplay.fullName(employee)}
                            <ListGroup.Icon />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                )}
                {visaAlerts.length > 0 && (
                  <div className={contractAlerts.length > 0 ? 'u-pt15' : ''}>
                    <React.Fragment>
                      <h4>在留期間更新が必要な従業員がいます</h4>
                      <ul>
                        {visaAlerts.map((employee) => (
                          <li className={styles.alert} key={employee.id}>
                            <Link to={`/employees/${employee.id}/visa_history`} className={styles.alert_link}>
                              {recordDisplay.fullName(employee)}
                              <span className={styles.expirationDate}>
                                在留期間満了日：{moment(employee.visa_history.visa_until).format('LL')}
                              </span>
                              <ListGroup.Icon />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  </div>
                )}
                <CustomAlerts />
                {comments.length > 0 && (
                  <div className="u-pt15">
                    <h4>従業員のメモ</h4>
                    <ul>
                      {comments.map((comment) => (
                        <li className={styles.alert} key={comment.employee.id} title={comment.body}>
                          <Link
                            to={{
                              pathname: `/employees/${comment.employee.id}`,
                              state: {from: 'alerts'},
                            }}
                            className={classnames(styles.alert_link, styles[`alert_link_${comment.color}`])}
                          >
                            {comment.employee.staff_code} {recordDisplay.fullName(comment.employee)}
                            <span className={styles.scheduledAlertDate}>
                              アラート予定日：{moment(comment.alert_at).format('LL')}
                            </span>
                            <span className={styles.commentBody}>{comment.body}</span>
                            <ListGroup.Icon />
                          </Link>
                        </li>
                      ))}
                      {extraCommentAlertsCount > 0 && (
                        <li className={styles.alert}>
                          <p className={styles.alert_link}>その他{extraCommentAlertsCount}件のメモアラートがあります</p>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.card_footer} />
          </div>
        </article>
      )
    )
  }
}

export default connect((state) => ({
  alerts: fetchSelector(state, 'alerts'),
  token: state.auth.token,
}))(Alerts)
