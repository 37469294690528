import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import _ from 'lodash'

import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import {Download} from 'jbc-front/components/icons'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Radio, RadioContainer} from 'jbc-front/components/presenters/form/Radio'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Note} from 'jbc-front/components/presenters/ui/Note'

import {Search} from '../../query'
import {TargetType, EncodingType} from 'types/api/exportAlertsCsv/exportAlertsCsv'
import {useDownloadAlertsCsv} from 'hooks/api/exportAlertsCsv/useDownloadAlertsCsv'
import {useAsyncDownloadAlertsCsv} from 'hooks/api/exportAlertsCsv/useAsyncDownloadAlertsCsv'
import {useFetchAlertsCount} from 'hooks/api/exportAlertsCsv/useFetchAlertsCount'
import {useFetchAlertsCsvStatus} from 'hooks/api/exportAlertsCsv/useFetchAlertsCsvStatus'

import styles from './CsvDownloadModal.scss'

interface CsvDownloadModalProps {
  search: Search
  defaultQuery: Search
}

interface CsvDownloadSchema {
  target: TargetType
  encoding: EncodingType
}

const MAX_DOWNLOAD_COUNT = 400

const hasConditions = (search: Search, defaultQuery: Search) => !_.isEqual(search, defaultQuery)

export const CsvDownloadModal: FC<CsvDownloadModalProps> = ({search, defaultQuery}) => {
  const {isLoading: isLoadingStatus, resource: isCreatingCsv, getter} = useFetchAlertsCsvStatus()
  const fetchAlertsCount = useFetchAlertsCount()
  const download = useDownloadAlertsCsv()
  const asyncDownload = useAsyncDownloadAlertsCsv()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const schema = yup.object({
    target: yup.mixed<TargetType>().required(),
    encoding: yup.mixed<EncodingType>().required(),
  })

  const {
    handleSubmit: handleReactHookFormSubmit,
    register,
    setValue,
  } = useForm<CsvDownloadSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      encoding: 'UTF-8',
      target: 'all',
    },
  })

  const handleSubmit = async (data: CsvDownloadSchema) => {
    const csvDownloadSearchParams = {
      sort_order: search.sortOrder,
      sort_column: search.sortColumn,
      alert_start_start_on: search.alertStartStartOn,
      alert_start_end_on: search.alertStartEndOn,
      base_date_start_on: search.baseDateStartOn,
      base_date_end_on: search.baseDateEndOn,
      alert_status: search.alertStatus,
      search_value: search.searchValue,
      alert_type_ids: search.alertTypeIds,
    }

    const countParams = {
      target: data.target,
      search: csvDownloadSearchParams,
    }
    const downloadParams = {...countParams, encoding: data.encoding}

    const res = await fetchAlertsCount(countParams)

    if (res.data < MAX_DOWNLOAD_COUNT) {
      await download(downloadParams)
    } else {
      await asyncDownload(downloadParams)
      await getter()
    }

    setIsOpen(false)
  }

  useEffect(() => {
    if (!isOpen) {
      const target = hasConditions(search, defaultQuery) ? 'search' : 'all'
      setValue('target', target)
    }
  }, [search])

  return (
    <>
      <Button
        onClick={handleOpen}
        className={styles.buttonWrap}
        disabled={isLoadingStatus || isCreatingCsv}
        disabledReason={
          isCreatingCsv ? '作成に時間がかかるため、完了しましたらメールにてお送りいたします。' : undefined
        }
      >
        アラート情報ダウンロード
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <form onSubmit={handleReactHookFormSubmit(handleSubmit)}>
          <Modal.Header onClose={handleClose}>アラート情報のダウンロード</Modal.Header>
          <Modal.Body>
            <Label>ファイル形式選択</Label>
            <Spacer direction="y" size={20} />
            <RadioContainer>
              <Radio {...register('encoding')} value="UTF-8">
                UTF-8（推奨）
              </Radio>
              <Radio {...register('encoding')} value="Windows-31J">
                Shift-JIS
              </Radio>
            </RadioContainer>
            <Spacer direction="y" size={6} />
            <Note>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。</Note>

            <Spacer direction="y" size={60} />

            <Label>ダウンロード対象選択</Label>
            <Spacer direction="y" size={20} />
            <RadioContainer>
              <Radio {...register('target')} value="all">
                全従業員
              </Radio>
              <Radio {...register('target')} value="search">
                検索絞込中の従業員
              </Radio>
            </RadioContainer>
            <Spacer direction="y" size={6} />
            <Note>
              全従業員：全従業員のアラート情報をダウンロードします。（※退職済みの従業員除く）
              <br />
              検索絞込中の従業員：検索絞込した従業員のアラート情報をダウンロードします。
            </Note>
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button primary onClick={handleReactHookFormSubmit(handleSubmit)}>
                <Download />
                ダウンロード
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
