import React, {useState} from 'react'
import {TextField, CheckboxField, SelectField, DateField, Section, Label, BoxDouble} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import {MyNumber} from 'reportForms/common'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {number} from 'validators'
import EmployeeName from 'procedures/EmployeeName'
import styles from 'reportForms/common.scss'
import _ from 'lodash'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R7Report'
const selector = formValueSelector(formName)

const lostTypes = [
  {value: 'others', label: '4. 退職等'},
  {value: 'passed_away', label: '5. 死亡'},
  {value: 'disability', label: '9. 障害認定'},
  {value: 'social_security_agreement', label: '11. 社会保障協定による喪失'},
]

export const LostType = ({lostType}) => {
  const lostOn =
    lostType === 'others' || lostType === 'passed_away' ? (
      <BoxDouble>
        <DateField name="lost_on" label="資格喪失原因日付" viewMode="years" />
      </BoxDouble>
    ) : null

  return (
    <React.Fragment>
      <SelectField
        name="lost_type"
        label="資格喪失原因"
        options={lostTypes}
        note="一般的な退職であれば「4 退職等」を選択します。"
      />
      {lostOn}
    </React.Fragment>
  )
}

export const Reached70Yo = ({isReached70Yo}) => (
  <React.Fragment>
    <CheckboxField name="is_reached_70_yo" label="70歳不該当" note="従業員が70歳以上の場合チェックしてください" />
    {isReached70Yo && (
      <BoxDouble>
        <DateField name="reached_70_yo_on" label="70歳不該当日付" viewMode="years" />
      </BoxDouble>
    )}
  </React.Fragment>
)

export const Notes = ({isOtherNotes}) => (
  <div className={styles.notes}>
    <Label text="備考" />
    <CheckboxField name="is_lost_multiple_office_worker" label="1. 二以上事業所勤務者の喪失" />
    <CheckboxField name="is_lost_continued_re_employment" label="2. 退職後の継続再雇用者の喪失" />
    <CheckboxField
      name="is_other_notes"
      label="3. その他"
      note={
        <p>
          下記のような事例に該当する場合は入力してください。
          <br />
          （１）転勤や雇用契約の変更による喪失の場合はその旨入力してください。
          <br />
          例：「○○年○○月○○日転勤」
          <br />
          （２）被保険者資格取得届を提出したが、年金事務所等から確認通知書などが送付される前に資格喪失した場合は、「資格取得届提出中」と入力してください。
          <br />
          （３）厚生年金基金の加入員である被保険者が資格取得した同月に資格を喪失する場合は、「加入員の同月得喪」と入力してください。
        </p>
      }
    />
    {isOtherNotes && (
      <div className={styles.notesOther}>
        <TextField name="notes_other" label="備考" />
      </div>
    )}
  </div>
)

const HealthInsurance = ({lostType, isReached70Yo, isOtherNotes}) => (
  <React.Fragment>
    <div className="u-mb30">
      <LostType lostType={lostType} />
      <Reached70Yo isReached70Yo={isReached70Yo} />
    </div>
    <div className="u-mb40">
      <Notes isOtherNotes={isOtherNotes} />
    </div>
    <div className="u-mb40">
      <Label text="資格確認書等回収" />
      <TextField name="attached_count" label="添付枚数" validate={number} />
      <TextField name="unable_to_return_count" label="返不能枚数" validate={number} />
    </div>
  </React.Fragment>
)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    submitting,
    handleSubmit,
    submitText,
    employee,
    lostType,
    healthInsuranceType,
    isReached70Yo,
    isOtherNotes,
    needUpgrade,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">健康保険厚生年金保険被保険者資格喪失届</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section>
            <HealthInsurance lostType={lostType} isReached70Yo={isReached70Yo} isOtherNotes={isOtherNotes} />
            {healthInsuranceType === 'kyokai' && (
              <div className="u-mb40">
                <MyNumber employee={employee} />
              </div>
            )}
            <SubmitedOn />
            <div className="u-ta-c u-mt30">
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => {
            setOpenModal(false)
            handleSubmit()
          }}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const connectedForm = connectForm('r7', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
    lostType: selector(state, 'lost_type'),
    isReached70Yo: selector(state, 'is_reached_70_yo'),
    isOtherNotes: selector(state, 'is_other_notes'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})

export default connectedForm
