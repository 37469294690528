import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import ListGroup from 'jbc-front/components/ListGroup'
import Progress from 'components/Progress'
import api from 'api'
import {titleMap} from 'procedureStatuses/Show'
import styles from 'employees/Show.scss'
import Navi from 'employees/Navi'
import {statuses} from 'procedureStatuses/List'
import DateOn from 'components/DateOn'
import Delete from 'employees/Delete'
import Infos from 'employees/show/Infos'
import {recordDisplay} from 'utils'
import {getSaveDisplayYearStyle, saveDisplayYearStyle} from 'employees/show/utils'
import comments from 'components/Comments'
import scrollSpy from 'jbc-front/components/ScrollSpy'
import compose from 'lodash/fp/compose'
import {User, ArrowDoublePrev, ArrowDoubleNext, Edit} from 'jbc-front/components/icons'
import {parse} from 'query-string'
import _ from 'lodash'
import {makeBackUrl} from 'utils'
import ActionButton from 'jbc-front/components/ActionButton'
import classNames from 'classnames'
import ScrollToTop from 'components/ScrollToTop'
import {WITH_AUTH_ADMIN_PATHS, generateDynamicPath} from 'consts/paths'
import {EmployeeAlerts} from 'employees/employeeAlerts/EmployeeAlerts'
import {RightExpansionContainerProvider} from 'components/feature/RightExpansionContainer'

const Comments = connect(
  (state) => ({
    currentUserId: state.session.currentUser.id,
  }),
  (dispatch, {employeeId, token}) => ({
    loadComment() {
      return api
        .createWithAuth(token)
        .employees.comments.list(employeeId)
        .then(({data: comments}) => comments)
    },
    addComment: (body) =>
      api
        .createWithAuth(token)
        .employees.comments.create(employeeId, body)
        .then(({data: comment}) => comment),

    updateComment: ({id, body, color, alert_at}) =>
      api
        .createWithAuth(token)
        .employees.comments.update(employeeId, id, {body, color, alert_at})
        .then(({data: comment}) => comment),

    deleteComment: (id) => api.createWithAuth(token).employees.comments.delete(employeeId, id),
  })
)(comments('employee'))

export const employeeDetailEmbed = (hideEmploymentInfo) =>
  hideEmploymentInfo
    ? [
        'employee_dependents',
        'employee_tax_classification',
        'health_insurance',
        'welfare_pension_insurance',
        'emergency_contact',
        'resident_card',
        'visa_history',
        'employment_insurance',
        'commuting_expenses',
        'employee_resumes',
        'employees_custom_values',
        'office',
        'detail_input_request',
        'detail_input_datum',
        'detail_input_comments',
        'detail_input_request_groups',
        'detail_input_request_custom_groups',
        'employee_dependents_in_single',
        'employee_contact_information',
        'employee_projects',
        'employee_skills',
        'employee_qualifications',
        'employee_languages',
        'employee_work_histories',
        'employee_education_backgrounds',
        'employee_tags',
        'employee_resident_tax',
        'comments',
      ]
    : [
        'employee_dependents',
        'employee_tax_classification',
        'health_insurance',
        'welfare_pension_insurance',
        'emergency_contact',
        'resident_card',
        'employment_insurance',
        'personnel_history',
        'personnel_history_with_code',
        'visa_history',
        'commuting_expenses',
        'employee_leave_of_absences',
        'employee_resumes',
        'contract_periods',
        'employees_custom_values',
        'office',
        'detail_input_request',
        'detail_input_datum',
        'detail_input_comments',
        'detail_input_request_groups',
        'detail_input_request_custom_groups',
        'employee_dependents_in_single',
        'employee_contact_information',
        'employee_projects',
        'employee_skills',
        'employee_qualifications',
        'employee_languages',
        'employee_work_histories',
        'employee_education_backgrounds',
        'employee_tags',
        'employee_resident_tax',
        'comments',
      ]

export const loadEmployee = async (id, token) => {
  const authedApi = api.createWithAuth(token)
  return authedApi.employees.get(id, employeeDetailEmbed(false))
}

const YearStyleSelector = ({yearStyle, onChangeYearStyle}) => (
  <div className={styles.yearStyleSelector}>
    <button
      className={yearStyle === 'seireki' ? styles.yearStyleSelectorActive : ''}
      onClick={() => onChangeYearStyle('seireki')}
    >
      西暦
    </button>
    <button
      className={yearStyle === 'wareki' ? styles.yearStyleSelectorActive : ''}
      onClick={() => onChangeYearStyle('wareki')}
    >
      和暦
    </button>
  </div>
)

class Procedures extends Component {
  static defaultProps = {
    procedureStatuses: [],
  }

  componentDidMount() {
    const {dispatch, token, id} = this.props
    dispatch(
      actionCreators.fetchData(
        'employee/procedure_statuses',
        api.createWithAuth(token).employees.procedureStatuses.list(id, {
          limit: 3,
          notDone: true,
        })
      )
    )
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('employee/procedure_statuses'))
  }

  render() {
    const {procedureStatuses} = this.props
    return (
      <ListGroup>
        {procedureStatuses.length > 0 ? (
          procedureStatuses.map((procedureStatus) => (
            <ListGroup.Item
              key={procedureStatus.id}
              className={styles.list}
              to={procedureStatus.link}
              as={procedureStatus.link ? Link : 'div'}
            >
              <div className={styles.procedure}>
                <div>{titleMap[procedureStatus.procedure_type]}</div>
                <span className={styles.tag}>{statuses[procedureStatus.status]}</span>
                <DateOn date={procedureStatus.date_on} />
              </div>
              <Progress className={styles.progress} progress={procedureStatus.progress} />
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item className={styles.list}>
            <div className={styles.noprocedure}>進行中の手続きはありません</div>
          </ListGroup.Item>
        )}
      </ListGroup>
    )
  }
}

const ProceduresContainer = connect((state) => ({
  token: state.auth.token,
  procedureStatuses: fetchSelector(state, 'employee/procedure_statuses').data,
  currentUser: state.session.currentUser,
}))(Procedures)

const History = ({id, isForeigner}) => {
  const path = generateDynamicPath(WITH_AUTH_ADMIN_PATHS.DOCUMENTS.EMPLOYEES.SHOW, [{pattern: 'id', replacement: id}])

  return (
    <div className={styles.history}>
      <Link to={`/employees/${id}/audit_logs`}>手続き・変更の履歴</Link>
      <Link to={`/employees/${id}/personnel_history`}>人事異動履歴一覧</Link>
      {isForeigner && <Link to={`/employees/${id}/visa_history`}>在留資格履歴一覧</Link>}
      <Link to={path}>作成された書類一覧</Link>
    </div>
  )
}

class Show extends Component {
  state = {
    yearStyle: getSaveDisplayYearStyle(),
    currentTabType: 1,
  }

  setCurrentTabType = (type) => {
    this.setState({currentTabType: type})
  }

  getActiveClass = (type, className) => (this.state.currentTabType === type ? className : '')

  getQuery(props = this.props) {
    const {
      location: {search},
    } = props
    const {query: queryStr, index: indexStr} = parse(search)
    if (!queryStr || !indexStr) {
      return {}
    }
    const index = +indexStr
    const query = JSON.parse(queryStr)
    if (index === 0) {
      query.limit = 2
      query.offset = 0
    } else {
      query.limit = 3
      query.offset = index - 1
    }
    return {query, index, queryStr}
  }

  fetchData(props = this.props) {
    const {id} = props.match.params
    const {token, client, dispatch} = props
    dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token, dispatch)))
    dispatch(actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list()))
    dispatch(
      actionCreators.fetchData(
        'custom_field_groups',
        api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
      )
    )
    dispatch(
      actionCreators.fetchData(
        'custom_fields',
        api.createWithAuth(token).employeeCustom.fields.list(client.id, {
          embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
        })
      )
    )

    const {query, index} = this.getQuery(props)
    if (query) {
      dispatch(
        actionCreators.fetchData(
          'neighbor_employees',
          api
            .createWithAuth(token)
            .employees.search(query)
            .then(({data: employees}) => {
              const next = _.last(employees)
              const prev = _.first(employees)
              if (index === 0) {
                if (employees[0] && Number(id) !== Number(employees[0].id)) {
                  // 「退職」などでemployee_statusが変わった場合
                  return {
                    prev: null,
                    next: employees[0],
                  }
                }
                return {
                  prev: null,
                  next,
                }
              }
              if (employees.length > 0 && employees.length < 3) {
                return {
                  prev,
                  next: null,
                }
              }
              if (employees.length === 3) {
                if (Number(id) !== Number(employees[1].id)) {
                  // 「退職」などでemployee_statusが変わった場合
                  return {
                    prev,
                    next: employees[1],
                  }
                }
                return {
                  prev,
                  next,
                }
              }
              return {
                prev: null,
                next: null,
              }
            })
        )
      )
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.fetchData(nextProps)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.employees.current.destroy())
    this.props.dispatch(actionCreators.fetchDestroy('neighbor_employees'))
    this.props.dispatch(actionCreators.fetchDestroy('employee_field_groups'))
    this.props.dispatch(actionCreators.fetchDestroy('custom_field_groups'))
    this.props.dispatch(actionCreators.fetchDestroy('custom_fields'))
  }

  render() {
    const {employee, currentUser, token, current, prev, next, location, fieldGroups, employeeFieldGroups} = this.props
    const {id} = this.props.match.params
    const {queryStr, index} = this.getQuery()
    const fromAlerts = this.props.location.state?.from === 'alerts'
    return (
      <RightExpansionContainerProvider>
        <div className={styles.main_grid}>
          <div className={styles.topNavi}>
            <div className="l-breadcrumb">
              <Link to="/employees" className="l-breadcrumb-link">
                従業員一覧
              </Link>
              <span className="l-breadcrumb-here">従業員情報</span>
            </div>
            <div className={styles.prevNextEmployees}>
              {prev && (
                <Link to={`/employees/${prev.id}/?query=${encodeURIComponent(queryStr)}&index=${index - 1}`}>
                  <ArrowDoublePrev className={styles.arrowIcon} />
                  {recordDisplay.fullName(prev)}
                </Link>
              )}
              {prev && next && <span className={styles.arrowSplit}>|</span>}
              {next && (
                <Link to={`/employees/${next.id}/?query=${encodeURIComponent(queryStr)}&index=${index + 1}`}>
                  {recordDisplay.fullName(next)}
                  <ArrowDoubleNext className={styles.arrowIcon} />
                </Link>
              )}
            </div>
          </div>
          <div className={styles.mainColumn}>
            <div className={[styles.leftColumn, styles.innerColumn].join(' ')}>
              <div
                className={classNames(
                  styles.photoName,
                  !currentUser.employee ? styles.notHaveJobcanHeader : styles.yesHaveJobcanHeader
                )}
              >
                <div>
                  {employee.icon && employee.icon.url ? (
                    <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.profileImg} />
                  ) : (
                    <User className={styles.profileImg} size={74} />
                  )}
                </div>
                <div className={styles.title}>
                  <div className={styles.titleInner}>
                    <span className={styles.titleName}>{recordDisplay.fullName(employee)}</span>
                    <span className={styles.titleStaffCode}>{recordDisplay(employee.staff_code)}</span>
                  </div>
                  <Comments employeeId={id} token={token} fromAlerts={fromAlerts} size={40} />
                </div>
              </div>
              <div className={styles.tabsContainer}>
                <div>
                  <ul className={styles.tabsWrap}>
                    <li
                      className={`${styles.tabs} ${this.getActiveClass(1, `${styles.activeTabs}`)}`}
                      onClick={() => this.setCurrentTabType(1)}
                    >
                      履歴
                    </li>
                    <li
                      className={`${styles.tabs} ${this.getActiveClass(2, `${styles.activeTabs}`)}`}
                      onClick={() => this.setCurrentTabType(2)}
                    >
                      進行中の手続き
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className={styles.tabsContentWrap}>
                    <li className={`${styles.tabsContent} ${this.getActiveClass(1, `${styles.activeTabsContent}`)}`}>
                      <History id={id} isForeigner={employee.national_type === 'foreigner'} />
                    </li>
                    <li className={`${styles.tabsContent} ${this.getActiveClass(2, `${styles.activeTabsContent}`)}`}>
                      <ProceduresContainer id={id} />
                    </li>
                  </ul>
                </div>
              </div>
              <Navi
                current={current}
                offset={612}
                bottomOffset={165}
                width={250}
                employeeFieldGroups={employeeFieldGroups}
              />
            </div>
            <div
              className={classNames(
                [styles.rigthColumn, styles.innerColumn].join(' '),
                !currentUser.employee ? styles.notHaveJobcanHeader : styles.yesHaveJobcanHeader
              )}
            >
              <div className={styles.btnList}>
                <ActionButton
                  className={styles.button}
                  as={Link}
                  to={`/employees/${id}/edit?back_to=${makeBackUrl(location)}`}
                >
                  <Edit />
                  編集
                </ActionButton>
                <span className={styles.employeeAlerts}>
                  <EmployeeAlerts id={id} />
                </span>
                <YearStyleSelector
                  yearStyle={this.state.yearStyle}
                  onChangeYearStyle={(newYearStyle) => {
                    saveDisplayYearStyle(newYearStyle)
                    this.setState({yearStyle: newYearStyle})
                  }}
                />
              </div>
              <Infos
                id={id}
                yearStyle={this.state.yearStyle}
                editLink={`/employees/${id}/edit?back_to=${makeBackUrl(location)}`}
                employeeFieldGroups={employeeFieldGroups}
                fieldGroups={fieldGroups}
              />
              <div className={styles.bottomNavi}>
                <Delete id={id}>従業員を削除</Delete>
                <div className={styles.prevNextEmployees}>
                  {prev && (
                    <Link to={`/employees/${prev.id}/?query=${encodeURIComponent(queryStr)}&index=${index - 1}`}>
                      <ArrowDoublePrev className={styles.arrowIcon} />
                      {recordDisplay.fullName(prev)}
                    </Link>
                  )}
                  {prev && next && <span className={styles.arrowSplit}>|</span>}
                  {next && (
                    <Link to={`/employees/${next.id}/?query=${encodeURIComponent(queryStr)}&index=${index + 1}`}>
                      {recordDisplay.fullName(next)}
                      <ArrowDoubleNext className={styles.arrowIcon} />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </RightExpansionContainerProvider>
    )
  }
}

export default compose(
  scrollSpy,
  connect((state) => ({
    employee: state.employees.current.data,
    currentUser: state.session.currentUser,
    token: state.auth.token,
    client: state.client.current,
    prev: fetchSelector(state, 'neighbor_employees').prev,
    next: fetchSelector(state, 'neighbor_employees').next,
    fieldGroups: fetchSelector(state, 'custom_field_groups').data,
    employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
  }))
)(Show)
