import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import Infos from 'employees/show/InfosEmployeeProfile'
import _ from 'lodash'
import {employeeDetailEmbed} from 'employees/Show'
import Navi from 'employees/Navi'
import scrollSpy from 'jbc-front/components/ScrollSpy'
import {EmployeeAlertNotifications} from './EmployeeAlertNotifications'
import {RightExpansionContainerProvider} from 'components/feature/RightExpansionContainer'
import classnames from 'classnames'
import styles from 'accountSettings/Profile.scss'

export const loadEmployee = async (id, token) => {
  const authedApi = api.createWithAuth(token)
  return authedApi.employees.get(id, employeeDetailEmbed(false))
}

const isRequesting = (employee) => ['applying', 'rejected'].includes(_.get(employee, 'detail_input_request.status'))

class Profile extends Component {
  componentDidMount() {
    const {token, client, loadData, currentUser, history} = this.props
    if (!currentUser) {
      history.replace('/account_settings/account')
    } else {
      loadData(currentUser, token, client)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      fieldGroups,
      employeeFieldGroups,
      employee: {detail_input_request},
      current,
    } = this.props

    return (
      <div className="l-wrap-xl">
        <h2 className={classnames('m-title-sub', styles.title)}>プロフィール</h2>
        <div className={styles.notesAndNotificationRow}>
          {isRequesting(this.props.employee) && (
            <h3 className={styles.notes}>更新依頼した情報は管理者の承認後に反映されます</h3>
          )}
          <RightExpansionContainerProvider>
            <span className={styles.employeeAlertNotifications}>
              <EmployeeAlertNotifications />
            </span>
          </RightExpansionContainerProvider>
        </div>
        <div className="l-flex">
          <div className="l-flex6-sub">
            <Navi
              current={current}
              offset={210}
              bottomOffset={165}
              width={250}
              employeeFieldGroups={employeeFieldGroups}
              className={styles.employeeSide}
            />
          </div>
          <div className="l-flex6-main">
            <Infos
              showComment={false}
              editLink="/account_settings/profile/edit"
              detailInputRequest={detail_input_request}
              fieldGroups={fieldGroups}
              employeeFieldGroups={employeeFieldGroups}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
  |> scrollSpy
  |> connect(
    (state) => ({
      employee: state.employees.current.data,
      token: state.auth.token,
      client: state.client.current,
      currentUser: state.session.currentUser,
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
    }),
    (dispatch) => ({
      loadData: (currentUser, token, client) => {
        dispatch(actionCreators.employees.current.fetchData(loadEmployee(currentUser.employee.id, token)))
        dispatch(
          actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list())
        )
        dispatch(
          actionCreators.fetchData(
            'custom_field_groups',
            api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
          )
        )
        dispatch(
          actionCreators.fetchData(
            'custom_fields',
            api.createWithAuth(token).employeeCustom.fields.list(client.id, {
              embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            })
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
    })
  )
