import {FC, Dispatch, SetStateAction, useState} from 'react'
import {Link} from 'react-router-dom'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {MainTitle} from 'components/layout/MainTitle'
import {PerPage} from 'components/ui/PerPage'
import {Content} from 'components/layout/Content'
import {Breadcrumb} from 'components/layout/Breadcrumb'

import Button from 'jbc-front/components/Button'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {MinusCircle} from 'jbc-front/components/icons'
import {Strage} from 'jbc-front/components/icons'

import {PaginatorWithResult} from './parts/PaginatorWithResult'

import type {Refetch} from './index'
import type {AlertType, AlertTypesQueryVariables} from './query'
import {List} from './parts/List'
import {SearchResult} from 'components/ui/SearchResult'

import styles from './Presenter.scss'

interface PresenterProps {
  totalCount: number
  alertTypes: AlertType[]
  refetch: Refetch
  variables: {
    page: number
    per: number
  }
  setVariables: Dispatch<SetStateAction<AlertTypesQueryVariables>>
  handleSaveOrdinals: (idList: number[], ordinalList: number[]) => Promise<void>
  loading: boolean
}

export const Presenter: FC<PresenterProps> = ({
  totalCount,
  alertTypes,
  refetch,
  variables,
  setVariables,
  handleSaveOrdinals,
  loading,
}) => {
  const paths = [
    {
      key: 'settings',
      to: WITH_AUTH_ADMIN_PATHS.SETTINGS.INDEX,
      label: '設定',
    },
  ]

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const [idList, setIdList] = useState<number[]>([])
  const [ordinalList, setOrdinalList] = useState<number[]>([])
  const setOrdinals = async (ids: number[], ordinals: number[]) => {
    setIdList(ids)
    setOrdinalList(ordinals)
  }

  const onClickSave = async () => {
    setIsDisabled(true)
    await handleSaveOrdinals(idList, ordinalList)
    setIsEditing(false)
    setIsDisabled(false)
  }

  return (
    <>
      <MainTitle title="アラートカスタマイズ" description="従業員情報の項目にアラートの設定を行うことができます" />
      <Content size="xl-3">
        <div className={styles.pageTopToolsContainer}>
          <Breadcrumb paths={paths} current="アラートカスタマイズ" />
          {!isEditing ? (
            <Link to="/settings/alert_custom/create">
              <Button> 新規作成 </Button>
            </Link>
          ) : (
            <></>
          )}
        </div>
        {!isEditing ? (
          <Button onClick={() => setIsEditing(true)}> ↑↓並び替え </Button>
        ) : (
          <div className={styles.buttonWrap}>
            <Button primary disabled={isDisabled} onClick={onClickSave}>
              <Strage size={17} /> 保存
            </Button>

            <Button
              disabled={isDisabled}
              onClick={() => {
                setIsEditing(false)
              }}
            >
              <MinusCircle size={17} /> キャンセル
            </Button>
          </div>
        )}

        <div className={styles.pagerContainer}>
          <div>
            <SearchResult total={totalCount} page={variables.page} limit={variables.per} />
          </div>
          <div>
            {!isEditing && (
              <PerPage
                selected={variables.per}
                onChange={(per: number) => setVariables((prev: AlertTypesQueryVariables) => ({...prev, per, page: 1}))}
              />
            )}
          </div>
        </div>
        <div className="l-overflow-scroll">
          <List
            alertTypes={alertTypes}
            isEditing={isEditing}
            setOrdinals={setOrdinals}
            refetch={refetch}
            page={variables.page}
            limit={variables.per}
          />
        </div>

        <Spacer size={20} />

        <PaginatorWithResult
          limit={variables.per}
          count={totalCount}
          page={variables.page}
          isLoading={loading}
          isEditing={isEditing}
          onClick={(page: number) => setVariables((prev) => ({...prev, page}))}
        />
      </Content>
    </>
  )
}
