import {gql} from '@apollo/client'

export const ALERT_TYPES = gql`
  query alert_types($per: Int!, $page: Int!) {
    alertTypes(per: $per, page: $page) {
      totalCount
      list {
        id
        color
        name
        description
        baseDateType
        offset
        days
        dateUnit
        active
        alertDateCondition
        alertTypeNotifications {
          id
          destination
          active
        }
        alertTypeOrdinal {
          ordinal
        }
      }
    }
  }
`

export interface AlertTypesQueryVariables {
  page: number
  per: number
}

export interface AlertTypesQueryResult {
  alertTypes: AlertTypes
}

export interface AlertTypes {
  list: AlertType[]
  totalCount: number
}

export interface AlertType {
  id: number
  color: number
  name: string
  description: string
  baseDateType: string
  offset: number
  days: number
  dateUnit: string
  active: string
  alertTypeNotifications: AlertTypeNotification[]
  alertTypeOrdinal: AlertTypeOrdinal
  alertDateCondition: string
}

export interface AlertTypeNotification {
  destination: string
  active: boolean
}

export interface AlertTypeOrdinal {
  ordinal: number
}

export interface AlertTypeOrdinalsQueryVariables {
  ids: [number]
  ordinals: [number]
}

export const UPDATE_ALERT_TYPE_ORDINALS = gql`
  mutation UpdateAlertTypeOrdinals($input: UpdateAlertTypeOrdinalsInput!) {
    updateAlertTypeOrdinals(input: $input) {
      alertTypes {
        id
        clientId
        alertTypeOrdinal {
          ordinal
        }
      }
    }
  }
`

export interface UpdateAlertTypeOrdinalsQueryVariables {
  input: {
    ids: number[]
    ordinals: number[]
  }
}

export interface UpdateAlertTypeOrdinalsQueryResult {
  alertTypes: AlertType[]
}

export const DELETE_ALERT_TYPE = gql`
  mutation DeleteAlertType($input: DeleteAlertInput!) {
    deleteAlertType(input: $input) {
      alertType {
        id
      }
    }
  }
`
