import React, {useState} from 'react'
import {SelectField, DateField, TextField, Section, CheckboxField} from 'jbc-front/components/Form'
import {reduxForm, formValueSelector} from 'redux-form'
import {Notes, SubmitedOn} from 'FormFields'
import connectForm from 'reportForms/connectForm'
import Button from 'jbc-front/components/Button'
import {MyNumber} from 'reportForms/common'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import _ from 'lodash'
import {toFormValues} from 'utils'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R6Report'
const selector = formValueSelector(formName)

const pensionTypes = [
  {value: '30', label: '30. 厚生年金保険・船員保険'},
  {value: '31', label: '31. 厚生年金保険・健康保険'},
  {value: '32', label: '32. 国家公務員共済組合'},
  {value: '36', label: '36. 地方公務員等共済組合'},
  {value: '37', label: '37. 日本私立学校振興・共済事業団'},
]

const dependentReasonSpouseOptions = [
  {value: 'a', label: '１. 配偶者の就職'},
  {value: 'i', label: '２. 婚姻'},
  {value: 'u', label: '３. 離職'},
  {value: 'e', label: '４. 収入減少'},
  {value: 'o', label: '５. その他'},
]

const AddDependentReason = ({isSpouse, reasonType}) => (
  <div>
    {isSpouse ? (
      <div>
        <SelectField
          name="dependent_reason_spouse"
          label="被扶養者（第3号被保険者）になった理由"
          options={dependentReasonSpouseOptions}
        />
        {reasonType === 'o' && <TextField name="dependent_reason_detail" label="理由詳細" />}
      </div>
    ) : null}
  </div>
)

const removeReasonTypes = [
  {value: 'passed_away', label: '死亡'},
  {value: 'divorce', label: '離婚'},
  {value: 'increase_in_income', label: '収入増加'},
  {value: 'others', label: 'その他'},
]

const RemoveDependentReason = ({isSpouse, reasonType}) => (
  <div>
    {isSpouse ? (
      <div>
        <SelectField
          name="remove_reason_type"
          label="被扶養者（第3号被保険者）から外れた理由"
          options={removeReasonTypes}
        />
        {reasonType === 'passed_away' && <DateField name="died_on" label="死亡日" />}
        {reasonType === 'others' && <TextField name="remove_reason_detail" label="理由詳細" />}
      </div>
    ) : null}
  </div>
)

const DependentReason = ({healthInsuranceType, procedureType, reasonType}) => {
  switch (procedureType) {
    case 'add_dependents':
    case 'enroll':
      if (healthInsuranceType !== 'kyokai') {
        return <AddDependentReason isSpouse={true} reasonType={reasonType} />
      } else {
        return <div />
      }
    case 'remove_dependents':
      if (healthInsuranceType === 'its') {
        return <RemoveDependentReason isSpouse={true} reasonType={reasonType} />
      } else {
        return <div />
      }
    default:
      return <div />
  }
}

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    submitting,
    handleSubmit,
    submitText,
    healthInsuranceType,
    procedureType,
    addReasonType,
    removeReasonType,
    employee,
    needUpgrade,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    let reasonType = null
    switch (procedureType) {
      case 'add_dependents':
      case 'enroll':
        reasonType = addReasonType
        break
      case 'remove_dependents':
        reasonType = removeReasonType
        break
      default:
        reasonType = null
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">国民年金第３号被保険者関係届</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section>
            <SelectField name="pension_type" label="種別" options={pensionTypes} />
            <DependentReason
              healthInsuranceType={healthInsuranceType}
              procedureType={procedureType}
              reasonType={reasonType}
            />

            <CheckboxField name="delegated" label="第３号被保険者関係届の提出は配偶者（第２号被保険者）に委任します" />

            <Notes />
            <MyNumber employee={employee} />
            <DateField name="accept_on" label="事業主等受付年月日" />
            <SubmitedOn />
            <div className="u-ta-c u-mt30">
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => handleSubmit()}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const connectedForm = connectForm('r6', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
    procedureType: _.get(state.procedureStatuses.current.data, 'procedure_type'),
    addReasonType: selector(state, 'dependent_reason_spouse'),
    removeReasonType: selector(state, 'remove_reason_type'),
    isUseMyNumber: selector(state, 'is_use_my_number'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  makeInitialValues: (formData) => {
    if (formData.procedureType === 'remove_dependents') {
      // FIX_ME
      // 手続きから配偶者の情報を取れないため暫定的に最後の配偶者を取っている
      // そのため削除後に別の配偶者を作成したりすると初期値に正しい値が入らない
      const spouse = _.filter(
        formData.employee.employee_dependents,
        (dependent) => dependent.relation_type === 'spouse'
      ).slice(-1)[0]
      const addedValuesForRemove = {
        remove_reason_type: spouse.remove_reason_type,
        remove_reason_detail: spouse.remove_reason_detail,
        died_on: spouse.died_on,
      }
      return toFormValues({...formData.data, ...addedValuesForRemove})
    } else {
      return toFormValues(formData.data)
    }
  },
})
export default connectedForm
