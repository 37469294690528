import {gql} from '@apollo/client'
import {Option} from 'jbc-front/components/presenters/form/Select'

export type Order = 'asc' | 'desc'
export type SortColumn = 'color' | 'alert_start' | 'alert_type_name' | 'base_date' | 'staff_code' | 'employee_name'

export interface EmployeeAlert {
  id: string
  alertStatus: string
  alertStart: string
  baseDate: string
  employee: Employee
  alertType: AlertType
}

export interface Employee {
  firstName: string
  lastName: string
  staffCode: string
}

export interface AlertType {
  name: string
  days: number
  dateUnit: 'year' | 'month' | 'day'
  color: number
  alertDateCondition: string
}

export interface Search {
  sortOrder: Order
  sortColumn: SortColumn
  alertStartStartOn: string
  alertStartEndOn: string
  baseDateStartOn: string
  baseDateEndOn: string
  alertStatus: 'in_progress' | 'completed' | null
  searchValue: string
  alertTypeIds: number[]
}

export interface SearchAlertQuery {
  per: number
  page: number
  search: Search
}

export interface SearchAlertQueryResult {
  employeeAlerts: {
    totalCount: number
    list: EmployeeAlert[]
  }
}

export interface SearchAlertInput {
  alertStartStartOn: string
  alertStartEndOn: string
  baseDateStartOn: string
  baseDateEndOn: string
  alertStatus: 'all' | 'in_progress' | 'completed'
  searchValue: string
  alertTypeIds: Option[]
}

export interface AlertTypeQueryResult {
  alertTypes: {
    list: AlertTypeOption[]
  }
}

export interface AlertTypeOption {
  id: string
  name: string
}

export const convertToOption = (options: AlertTypeOption[]): Option[] => {
  return options.map(({id, name}) => ({
    value: id,
    label: name,
  }))
}

export const ALERT_TYPES = gql`
  query alertTypes {
    alertTypes(per: 50, page: 1) {
      list {
        id
        name
      }
    }
  }
`

export const EMPLOYEE_ALERTS = gql`
  query employeeAlerts($per: Int!, $page: Int!, $search: EmployeeAlertSearchInput) {
    employeeAlerts(per: $per, page: $page, search: $search) {
      totalCount
      list {
        id
        alertStart
        baseDate
        alertStatus
        alertType {
          name
          days
          dateUnit
          baseDateType
          color
          alertDateCondition
        }
        employee {
          firstName
          lastName
          staffCode
        }
      }
    }
  }
`

export const COMPLETE_EMPLOYEE_ALERT = gql`
  mutation CompleteEmployeeAlert($input: CompleteEmployeeAlertInput!) {
    completeEmployeeAlert(input: $input) {
      employeeAlert {
        id
      }
    }
  }
`
