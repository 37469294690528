import {FC, MouseEventHandler, ReactNode} from 'react'
import {Pulldown} from 'jbc-front/components/icons'
import {EMPLOYEE_ALERT_STATUSES} from 'consts/employeeAlert'
import {EmployeeAlert} from '../../query'
import styles from './AlertList.scss'

// @ts-ignore
import DropDownMenu from 'components/DropDownMenu'

interface MenuItem {
  label: ReactNode
  style?: string
  onClick: () => void
}

interface CheckCompleteMenuProps {
  alert: EmployeeAlert
  menuItems: MenuItem[]
}

export const CheckCompleteMenu: FC<CheckCompleteMenuProps> = ({alert, menuItems = []}) => {
  interface ToggleProps {
    onClick: MouseEventHandler<HTMLDivElement>
  }
  const toggle: FC<ToggleProps> = ({onClick}) => (
    <div className={styles.alertStatus} onClick={onClick}>
      <div className="u-mr5">{EMPLOYEE_ALERT_STATUSES[alert.alertStatus]}</div>
      <Pulldown />
    </div>
  )

  return <DropDownMenu toggle={toggle} menuItems={menuItems} />
}
