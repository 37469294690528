import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'
import {useFetchEmployeeAlertNotifications} from 'hooks/api/employeeAlertNotifications/useFetchEmployeeAlertNotifications'
import {useFetchHasNotifications} from 'hooks/api/employeeAlertNotifications/useFetchHasNotifications'
import {EmployeeAlertNotification} from 'types/api/employeeAlertsNotifications/employeeAlertNotifications'
import {useRightExpansionContainer} from 'components/feature/RightExpansionContainer'
import ActionButton from 'jbc-front/components/ActionButton'
import {AlertNotification} from 'jbc-front/components/icons'
import {LoadingPage} from 'components/ui/LoadingPage'
import classnames from 'classnames'
import {dateFormat} from 'libs/formatter'
import _ from 'lodash'
import styles from './EmployeeAlertNotifications.scss'
import 'froala-editor/css/froala_editor.pkgd.min.css'

type TabType = 'alert'

const parseHtml = (html: string) => {
  if (!html) {
    return {__html: ''}
  }
  let escaped = _.escape(html)
  escaped = escaped.replace(
    /&lt;a href=(&quot;|&#39;)(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)(&quot;|&#39;)&gt;(.+?)&lt;\/a&gt;/g,
    '<a href="$2" target="_blank" class="u-txt-link" rel="noopener noreferrer">$4</a>'
  )
  escaped = escaped.replace(
    /(^|[^"'>])(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g,
    '$1<a href="$2" target="_blank" class="u-txt-link" rel="noopener noreferrer">$2</a>'
  )
  escaped = escaped.replace(/&lt;b&gt;(.+?)&lt;\/b&gt;/g, '<b>$1</b>')
  escaped = escaped.replace(/&lt;u&gt;(.+?)&lt;\/u&gt;/g, '<u>$1</u>')
  escaped = escaped.replace(/&lt;i&gt;(.+?)&lt;\/i&gt;/g, '<i>$1</i>')
  escaped = escaped.replace(/\r\n|\r|\n/g, '<br />')

  return {__html: _.unescape(escaped)}
}

export const SideContent = () => {
  const dispatch = useDispatch()
  const {isLoading, resource, errors} = useFetchEmployeeAlertNotifications()
  const [alertNotifications, setAlertNotifications] = useState<EmployeeAlertNotification[]>([])
  const [current, setCurrent] = useState<TabType>('alert')

  useEffect(() => {
    const getAlertNotifications = () => {
      if (!isLoading) {
        if (errors) {
          dispatch(asyncError(errors))
          return
        }
        setAlertNotifications(resource)
      }
    }

    getAlertNotifications()
  }, [isLoading])

  return (
    <div className={styles.sideContent}>
      <div className={styles.tabContainer}>
        <div
          className={classnames(current === 'alert' ? styles.activeTab : styles.tab)}
          onClick={() => setCurrent('alert')}
        >
          アラート通知
        </div>
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : (
        current === 'alert' &&
        (alertNotifications.length > 0 ? (
          alertNotifications.map((notification, index) => (
            <div key={index} className={styles.alertNotificationContainer}>
              <div className={styles.firstRowContainer}>
                <span className={styles.circle} />
                <p className={styles.subject}>{notification.subject}</p>
              </div>
              <p>（通知日：{dateFormat(notification.date, 'seireki')}）</p>

              <div id="preview" className="fr-box fr-document fr-ltr fr-basic fr-top">
                <div className="fr-element fr-view fr-disabled" dir="ltr">
                  <p dangerouslySetInnerHTML={parseHtml(notification.body)} className={styles.body} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noNotificationsMessage}>アラート通知はありません</div>
        ))
      )}
    </div>
  )
}

export const EmployeeAlertNotifications = () => {
  const {setContent} = useRightExpansionContainer()
  const {resource} = useFetchHasNotifications()

  const handleClick = () => {
    const key = 'employeeAlertNotification'
    setContent(<SideContent />, key, 400)
  }

  return (
    <div className={styles.buttonContainer}>
      <ActionButton onClick={handleClick} className={styles.alertButton}>
        <AlertNotification className={styles.notificationIcon} />
        アラート表示
      </ActionButton>
      <span className={classnames(styles.badge, {[styles.visible]: resource})}></span>
    </div>
  )
}
