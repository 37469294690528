import axios from 'axios'
import Cookies from 'js-cookie'
import {flow, groupBy, mapKeys} from 'lodash/fp'
import _ from 'lodash'
import employees from 'api/employees'
import procedureStatuses, {toBase64, toArrayBuffer} from 'api/procedureStatuses'
import tm from 'api/tm'
import postWithFile from 'api/postWithFile'
import {selectedOffices} from 'offices/utils'

export function createWithAuth(token) {
  const roumuAxios = axios.create({
    headers: {
      Authentication: token,
      'X-Office-Ids': selectedOffices.value,
    },
  })

  return {
    tm: tm(roumuAxios),
    employees: employees(roumuAxios),
    clients: {
      getCurrent(embed) {
        return roumuAxios
          .get('/api/current_client', {params: embed ? {embed} : {}})
          .then((res) => res.data.current_client)
      },
      update(id, params) {
        return roumuAxios.put(`/api/clients/${id}`, params)
      },
      getMonthlyBillings: () => {
        return roumuAxios.get('/api/current_client/client_monthly_billings').then(({data}) => ({data}))
      },
      getPaymentInfo: () => {
        return roumuAxios.get('/api/current_client/payment_info').then(({data}) => ({data}))
      },
      changePaymentMethod: (data) => {
        return roumuAxios.post('/api/current_client/change_payment_method', data)
      },
      laborConsultant: {
        get(clientId) {
          return roumuAxios.get(`/api/clients/${clientId}/labor_consultant`).then((res) => ({data: res.data}))
        },
        createOrUpdate(clientId, data, withFile) {
          const method = data.id ? roumuAxios.patch : roumuAxios.post
          if (withFile) {
            return postWithFile(
              data,
              ['egov_acting_certificate_file'],
              method.bind(roumuAxios, `/api/clients/${clientId}/labor_consultant`)
            )
          } else {
            return method.call(roumuAxios, `/api/clients/${clientId}/labor_consultant`, data)
          }
        },
      },
      payroll: {
        import() {
          return roumuAxios.post('/api/jbc/payroll/client/import')
        },
        syncStatuses() {
          return roumuAxios.get('/api/jbc/payroll/sync_statuses').then((res) => ({data: res.data}))
        },
      },
    },
    offices: {
      list(params) {
        return roumuAxios.get('/api/offices', {params}).then((res) => ({
          data: res.data,
          count: res.headers['x-total-count'],
        }))
      },
      create(params) {
        return roumuAxios.post('/api/offices', params)
      },
      update(id, params) {
        return roumuAxios.patch(`/api/offices/${id}`, params)
      },
      delete(id) {
        return roumuAxios.delete(`/api/offices/${id}`)
      },
      get(id, params) {
        return roumuAxios.get(`/api/offices/${id}`, {params}).then((res) => ({
          data: res.data,
        }))
      },
    },
    procedureStatuses: procedureStatuses(roumuAxios),
    csvProcedureStatuses: {
      list(query, embed = []) {
        return roumuAxios
          .get('/api/csv_procedure_statuses', {
            params: {
              _page: query.page,
              status: query.status,
              limit: query.per_page,
              q: query.search_keyword,
              procedure_type: query.procedure_type,
              procedure_kind: query.procedure_kind,
              sort: query.sort_column,
              order: query.order,
              embed,
            },
          })
          .then((res) => {
            const count = res.headers['x-total-count']
            return {data: res.data, count: count}
          })
      },
    },

    todos: {
      update(id, status) {
        return roumuAxios.put(`/api/todos/${id}`, {status: status})
      },
    },

    relatedMaternityProcedure: {
      get(id) {
        return roumuAxios.get(`/api/procedure_maternity_relations/${id}`).then(({data}) => data)
      },
    },

    users: {
      getCurrent() {
        return roumuAxios.get('/api/me?embed=client_role,employee,office,personnel_history,customer_code')
      },
    },

    doLogout: () => {
      return roumuAxios.delete('/api/login').then(() => ({}))
    },

    requestProcedure: (employeeId, procedureType, message) => {
      return roumuAxios.post('/api/request_employee_info_to_input', {
        employee_id: employeeId,
        procedure_type: procedureType,
        ...(message ? {message} : {}),
      })
    },

    finishRequest: (requestStatusId) => {
      return roumuAxios.post(`/api/received_request_statuses/${requestStatusId}/done`)
    },

    nations: {
      list() {
        return roumuAxios.get('/api/nations').then(({data}) => ({
          nations: data,
        }))
      },
    },

    visaTypes: {
      list() {
        return roumuAxios.get('/api/visa_types').then(({data}) => ({
          visaTypes: data,
        }))
      },
    },

    getAddressByPostcode: (postcode) => {
      return roumuAxios.get('/api/postcodes', {params: {postcode}}).then(({data}) => data[0])
    },

    standardMonthlyAmounts: {
      list() {
        return roumuAxios
          .get('/api/standard_monthly_amounts', {
            params: {
              insurance_type: 'all',
            },
          })
          .then(({data}) =>
            flow(
              groupBy('insurance_type'),
              mapKeys((key) => `${key}StandardMonthlyAmounts`)
            )(data)
          )
      },
    },
    positions: {
      list() {
        return roumuAxios.get('/api/positions').then(({data}) => ({
          positions: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/positions', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/positions/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/positions/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_positions', {positions: params})
      },
    },
    tags: {
      list() {
        return roumuAxios.get('/api/tags').then(({data}) => ({
          tags: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/tags', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/tags/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/tags/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_tags', {positions: params})
      },
    },

    allowedIps: {
      list(flag) {
        return roumuAxios.get(`/api/allowed_ips?flag=${flag}`).then(({data}) => ({
          data,
        }))
      },
      updateAll(params, flag) {
        return roumuAxios.post(`/api/bulked_allowed_ips?flag=${flag}`, {allowed_ips: params})
      },
    },

    additionalNotificationDsts: {
      list() {
        return roumuAxios.get('/api/settings/additional_notification_dsts').then(({data}) => ({
          data,
        }))
      },
      updateAll(params) {
        return roumuAxios.post('/api/settings/bulked_additional_notification_dsts', {
          additional_notification_dsts: params,
        })
      },
    },

    occupations: {
      list() {
        return roumuAxios.get('/api/occupations').then(({data}) => ({
          occupations: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/occupations', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/occupations/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/occupations/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_occupations', {occupations: params})
      },
    },
    languages: {
      list() {
        return roumuAxios.get('/api/languages').then(({data}) => ({
          languages: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/languages', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/languages/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/languages/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_languages', {languages: params})
      },
    },
    languageLevels: {
      list() {
        return roumuAxios.get('/api/language_levels').then(({data}) => ({
          languageLevels: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/language_levels', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/language_levels/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/language_levels/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_language_levels', {language_levels: params})
      },
    },
    skills: {
      list() {
        return roumuAxios.get('/api/skills').then(({data}) => ({
          skills: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/skills', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/skills/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/skills/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_skills', {skills: params})
      },
    },
    qualifications: {
      list() {
        return roumuAxios.get('/api/qualifications').then(({data}) => ({
          qualifications: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/qualifications', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/qualifications/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/qualifications/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_qualifications', {qualifications: params})
      },
    },
    groups: {
      list() {
        return roumuAxios.get('/api/groups').then(({data}) => ({
          groups: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/groups', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/groups/${id}`, {name})
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_groups/', {groups: params})
      },
      delete(id) {
        return roumuAxios.delete(`/api/groups/${id}`)
      },
    },
    employmentTypes: {
      list() {
        return roumuAxios.get('/api/employment_types').then(({data}) => ({
          employmentTypes: data,
        }))
      },
      create(name) {
        return roumuAxios.post('/api/employment_types', {name})
      },
      update(id, name) {
        return roumuAxios.patch(`/api/employment_types/${id}`, {name})
      },
      delete(id) {
        return roumuAxios.delete(`/api/employment_types/${id}`)
      },
      updateAll(params) {
        return roumuAxios.post('/api/bulked_employment_types', {employment_types: params})
      },
    },
    auditLogs: {
      list(query, embed, params) {
        return roumuAxios
          .get('/api/audit_logs', {
            params: _.pickBy(
              {
                _page: query.page,
                limit: query.per_page,
                ...params,
              },
              (value) => value
            ),
          })
          .then((res) => {
            const count = res.headers['x-total-count']
            return {data: res.data, count: count}
          })
      },
      search(query) {
        return roumuAxios.get('/api/audit_logs', {params: query}).then((res) => {
          const count = res.headers['x-total-count']
          return {data: res.data, count: count}
        })
      },
      get(id) {
        return roumuAxios.get(`/api/audit_logs/${id}?changes=1`).then((res) => {
          return {data: res.data}
        })
      },
    },
    notifications: {
      list(query) {
        return roumuAxios
          .get('/api/notifications', {
            params: _.pickBy(
              {
                _page: query._page,
                limit: query.limit,
                from_employee: query.from_employee,
                q: query.q,
                notification_type: query.notification_type,
                created_at_start: query.created_at_start,
                created_at_end: query.created_at_end,
              },
              (value) => value
            ),
          })
          .then((res) => {
            const count = res.headers['x-total-count']
            return {data: res.data.payload, count: count, unread_count: res.data.meta.unread_count}
          })
      },
      get(id) {
        return roumuAxios.get(`/api/audit_logs/${id}`).then((res) => {
          return {data: res.data}
        })
      },
    },
    systemAnnounces: {
      list(query) {
        return roumuAxios
          .get('/api/system_announces', {
            params: _.pickBy(
              {
                _page: query.page,
                limit: query.per_page,
              },
              (value) => value
            ),
          })
          .then((res) => {
            const count = res.headers['x-total-count']
            return {data: res.data, count: count}
          })
      },
      get(id) {
        return roumuAxios.get(`/api/system_announces/${id}`).then((res) => {
          return {data: res.data}
        })
      },
    },
    changePassword(values) {
      return roumuAxios.post('/api/settings/change_password', values)
    },
    changeEmail(values) {
      return roumuAxios.post('/api/settings/change_email', values)
    },
    usersClients: {
      list(query, params) {
        return roumuAxios
          .get('/api/settings/users_clients', {
            params: _.pickBy(
              {
                _page: query.page,
                limit: query.per_page,
                ...params,
              },
              (value) => value
            ),
          })
          .then((res) => ({
            data: res.data,
            count: res.headers['x-total-count'],
          }))
      },
      updateMulti(data) {
        return roumuAxios.post('/api/settings/bulked_users_clients', data)
      },
    },
    inviteUser: {
      create(data) {
        return roumuAxios.post('/api/invite_just_user_to_my_page', data)
      },
    },
    alerts: {
      contractAlerts() {
        return roumuAxios.get('/api/contract_alerts').then((res) => ({data: res.data}))
      },
      visaAlerts() {
        return roumuAxios.get('/api/visa_alerts').then((res) => ({data: res.data}))
      },
      commentAlerts() {
        return roumuAxios.get('/api/comment_alerts').then((res) => ({data: res.data}))
      },
      customAlertsCount(params) {
        return roumuAxios
          .post('/api/export_alerts_csv_request/download_count', params)
          .then((res) => ({data: res.data}))
      },
    },
    myNumber: {
      request(data) {
        return roumuAxios.post('/api/request_my_number_info_to_input', data)
      },
      requestMulti(data) {
        return roumuAxios.post('/api/bulked_request_my_number_info_to_input', data)
      },
      finishInput() {
        return roumuAxios.post('/api/request_my_number_info_to_input/done')
      },
      import(params) {
        return postWithFile(params, ['file'], roumuAxios.post.bind(roumuAxios, '/api/import_employee_my_numbers_csv'))
      },
      summary() {
        return roumuAxios.get('/api/my_numbers/summary').then((res) => ({data: res.data}))
      },
    },
    businessPartners: {
      list(params) {
        return roumuAxios.get('/api/business_partners', {params}).then((res) => ({
          data: res.data,
          count: res.headers['x-total-count'],
        }))
      },
      get(id, params) {
        return roumuAxios.get(`/api/business_partners/${id}`, {params}).then((res) => ({
          data: res.data,
        }))
      },
      create(data) {
        return roumuAxios.post('/api/business_partners', data)
      },
      update(id, data) {
        return roumuAxios.patch(`/api/business_partners/${id}`, data)
      },
      request(data) {
        return roumuAxios.post('/api/request_my_number_info_to_input_bp', data)
      },
      delete(id) {
        return roumuAxios.delete(`/api/business_partners/${id}`)
      },
      import(params) {
        return postWithFile(params, ['file'], roumuAxios.post.bind(roumuAxios, '/api/import_business_partners_csv'))
      },
      myNumber: {
        get(businessPartnerId) {
          return roumuAxios
            .get(`/api/business_partners/${businessPartnerId}/my_number`)
            .then((res) => ({data: res.data}))
        },
        create(businessPartnerId, params) {
          return postWithFile(
            params,
            ['my_number_image', 'id_image_0', 'id_image_1'],
            roumuAxios.post.bind(null, `/api/business_partners/${businessPartnerId}/my_number`)
          )
        },
        delete(businessPartnerId) {
          return roumuAxios.delete(`/api/business_partners/${businessPartnerId}/my_number`)
        },
      },
    },
    employeeCustom: {
      fieldGroups: {
        list(clientId, params = {}) {
          return roumuAxios
            .get(`/api/clients/${clientId}/custom_employee_field_groups`, {params})
            .then((res) => ({data: _.sortBy(res.data, 'position')}))
        },
        get(clientId, fieldGroupId, params = {}) {
          return roumuAxios
            .get(`/api/clients/${clientId}/custom_employee_field_groups/${fieldGroupId}`, {params})
            .then((res) => ({data: res.data}))
        },
        update(clientId, fieldGroupId, data) {
          return roumuAxios.patch(`/api/clients/${clientId}/custom_employee_field_groups/${fieldGroupId}`, data)
        },
        delete(clientId, fieldGroupId) {
          return roumuAxios.delete(`/api/clients/${clientId}/custom_employee_field_groups/${fieldGroupId}`)
        },
        updateAll(data) {
          return roumuAxios.post('/api/bulked_custom_employee_field_groups', data)
        },
      },
      fields: {
        list(clientId, params = {}) {
          return roumuAxios
            .get(`/api/clients/${clientId}/custom_employee_fields`, {params})
            .then((res) => ({data: _.sortBy(res.data, 'position')}))
        },
        updateAll(data) {
          return roumuAxios.post('/api/bulked_custom_employee_fields', data)
        },
      },
    },
    applicantInformation: {
      get() {
        return roumuAxios.get('/api/egov_procedure_applicant_information').then(({data}) => ({data}))
      },
      create(data) {
        return roumuAxios.post('/api/egov_procedure_applicant_information', data)
      },
    },
    contactInformation: {
      get() {
        return roumuAxios.get('/api/egov_procedure_contact_information').then(({data}) => ({data}))
      },
      create(data) {
        return roumuAxios.post('/api/egov_procedure_contact_information', data)
      },
    },
    certificateFile: {
      get() {
        return roumuAxios.get('/api/egov_certificate_file').then(({data}) => ({data}))
      },
      create(data) {
        return postWithFile(data, ['file'], roumuAxios.post.bind(roumuAxios, '/api/egov_certificate_file'))
      },
    },
    submissionDestination: {
      get() {
        return roumuAxios.get('/api/egov_procedure_submission_destination').then(({data}) => ({data}))
      },
      create(data) {
        return roumuAxios.post('/api/egov_procedure_submission_destination', data)
      },
    },
    governmentOffices: {
      list(prefectureId, governmentOfficeType) {
        return roumuAxios
          .get('/api/government_offices', {
            params: {prefecture_id: prefectureId, government_office_type: governmentOfficeType},
          })
          .then(({data}) => ({data}))
      },
    },
    eGovProcedure: {
      prepare(data) {
        return roumuAxios.post('/api/egov_procedure_statuses/prepare', data)
      },
      apply(eGovId, data) {
        return roumuAxios.post(`/api/egov_procedure_statuses/${eGovId}/apply`, data)
      },
      get(id, params = {}) {
        return roumuAxios
          .get(`/api/egov_procedure_statuses/${id}`, {
            params,
          })
          .then((res) => {
            return {data: res.data}
          })
      },
    },
    rosterOfEmployees: {
      list() {
        return roumuAxios.get('/api/roster_of_employees_requests').then(({data}) => ({data}))
      },
      create(params) {
        return roumuAxios.post('/api/roster_of_employees_requests', params)
      },
    },
    employeeCsvRequests: {
      list() {
        return roumuAxios.get('/api/export_employees_csv_requests').then(({data}) => ({data}))
      },
      create(params) {
        return roumuAxios.post('/api/export_employees_csv_requests', params)
      },
    },
    personnelHistoryCsvRequests: {
      list() {
        return roumuAxios.get('/api/export_personnel_histories_csv_requests').then(({data}) => ({data}))
      },
      create(params) {
        return roumuAxios.post('/api/export_personnel_histories_csv_requests', params)
      },
    },
    requestToUpload: {
      create(params) {
        return roumuAxios.post('/api/request_to_upload', params)
      },
    },
    uploadedFiles: {
      employeeFiles(params = {}) {
        return roumuAxios.get('/api/employees_files', {params}).then((res) => ({data: res.data}))
      },
      clientFiles(params = {}) {
        return roumuAxios.get('/api/clients_files', {params}).then((res) => {
          const {headers, data} = res
          return {
            data,
            hasMoreProcedureFiles: headers['x-displays-see-more-proc'] === 'true',
            hasMoreOtherFiles: headers['x-displays-see-more-not-proc'] === 'true',
          }
        })
      },
      create(data) {
        return postWithFile(data, ['file'], roumuAxios.post.bind(roumuAxios, '/api/uploaded_files'))
      },
      update(id, data) {
        return roumuAxios.patch(`/api/uploaded_files/${id}`, data)
      },
      delete(id) {
        return roumuAxios.delete(`/api/uploaded_files/${id}`)
      },
      fileInfos: {
        update(id, data) {
          return roumuAxios.patch(`/api/file_infos/${id}`, data)
        },
      },
    },
    detailInputData: {
      get() {
        return roumuAxios
          .get('/api/detail_input_datum')
          .then((res) => ({data: res.data}))
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              return {finished: true}
            }
            throw err
          })
      },
      create(data, options) {
        return roumuAxios.post('/api/detail_input_datum', data, options)
      },
    },
    jbcToken: {
      get() {
        return roumuAxios.get('/api/current_client?embed=token').then((res) => {
          return {data: res.data.current_client.token}
        })
      },
    },
    asyncTasks: {
      list() {
        return roumuAxios.get('/api/async_tasks').then((res) => ({data: res.data}))
      },
      confirm(id) {
        return roumuAxios.post(`/api/async_tasks/${id}/confirm`)
      },
    },
    YearEndAdj: {
      start() {
        return roumuAxios.get('/api/start-yea')
      },
      create() {
        return roumuAxios.post('/api/start-yea')
      },
      startRequest(requestToken) {
        return roumuAxios.get(`/api/start-yea-request?request_token=${requestToken}`)
      },
    },
    StressCheck: {
      start() {
        return roumuAxios.get('/api/start-check')
      },
      create(roleType) {
        return roumuAxios.post('/api/start-check', {role_type: roleType})
      },
      startRequest(requestToken) {
        return roumuAxios.get(`/api/start-check-request?request_token=${requestToken}`)
      },
      showStressCheckStatus() {
        return roumuAxios.get('/api/stress-check-status')
      },
    },
    employeeFieldGroups: {
      list() {
        return roumuAxios.get('/api/employee_field_groups').then((res) => ({data: res.data}))
      },
      get(fieldGroupId) {
        return roumuAxios.get(`/api/employee_field_groups/${fieldGroupId}`).then((res) => ({data: res.data}))
      },
      update(fieldGroupId, params) {
        return roumuAxios.put(`/api/employee_field_groups/${fieldGroupId}`, params)
      },
    },
    talentDisplaySettings: {
      list() {
        return roumuAxios.get('/api/talent_display_settings').then((res) => ({data: res.data}))
      },
      updateAll(data) {
        return roumuAxios.post('/api/bulked_talent_display_settings', {talent_display_settings: data})
      },
    },
    talentSearchSettings: {
      list() {
        return roumuAxios.get('/api/talent_search_settings').then((res) => ({data: res.data}))
      },
      updateAll(data) {
        return roumuAxios.post('/api/bulked_talent_search_settings', {talent_search_settings: data})
      },
    },
    schoolTypes: {
      list() {
        return roumuAxios.get('/api/school_types').then(({data}) => ({
          schoolTypes: data,
        }))
      },
    },
    documents: {
      comments: {
        list(documentId) {
          return roumuAxios.get(`/api/documents/${documentId}/comments`)
        },

        create(documentId, body) {
          return roumuAxios.post(`/api/documents/${documentId}/comments`, {body})
        },

        update(documentId, commentId, body) {
          return roumuAxios.patch(`/api/documents/${documentId}/comments/${commentId}`, {body})
        },

        delete(documentId, commentId) {
          return roumuAxios.delete(`/api/documents/${documentId}/comments/${commentId}`)
        },
      },
    },
    documentEmployees: {
      list(query, embed = [], params = {}) {
        return roumuAxios
          .get('/api/documents_by_employees', {
            params: _.pickBy(
              {
                _page: query.page,
                employment_status: query.employment_status,
                limit: query.per_page,
                q: query.search_keyword,
                sort: query.sort_column,
                order: query.order,
                embed,
                ...params,
              },
              (value) => value
            ),
          })
          .then((res) => {
            const count = res.headers['x-total-count']
            return {data: res.data, count: count}
          })
      },
      search(query) {
        const {embed = []} = query
        return roumuAxios.get('/api/documents_by_employees', {params: {...query, embed: [...embed]}}).then((res) => {
          const count = res.headers['x-total-count']
          return {data: res.data, count: count}
        })
      },
    },
    dynamicDocuments: {
      getPdf(documentId, params = {}) {
        return roumuAxios
          .get(`/api/dynamic_documents/${documentId}.pdf`, {
            responseType: 'blob',
            params,
          })
          .then(toArrayBuffer)
          .then(toBase64)
      },
    },
  }
}

export function setDefaultHeaders() {
  const token = Cookies.get('access_token')
  axios.defaults.headers.common['Authentication'] = token
}

export function doLogin(params) {
  return axios.post('/api/login/', params)
}

export function roumuCreateRegistration(params) {
  return axios.post('/api/registrations', params)
}

export function roumuCreateRegistrationInterestedEmail(params) {
  return axios.post('/api/interested_email', params)
}

export function roumuCheckInvitationToken(token) {
  return axios.get('/api/invitation_confirmation', {params: {token: token}})
}

export function inviteeInitialPassword(token, values) {
  return axios.post('/api/invitee_initial_password', {token: token, ...values})
}

export function requestResetPassword(values) {
  return axios.post('/api/request_reset_password', values)
}

export function resetPassword(values) {
  return axios.post('/api/reset_password', values)
}

export function checkResetPasswordToken(token) {
  return axios.get('/api/reset_password', {params: {token: token}})
}

const businessPartnerInput = {
  get(token) {
    return axios.get('/api/my_numbers/for_guest', {params: {token}}).then((res) => ({data: res.data}))
  },
  create(data) {
    return postWithFile(
      data,
      ['my_number_image', 'id_image_0', 'id_image_1'],
      axios.post.bind(axios, '/api/my_numbers/for_guest')
    )
  },
}

export default {
  doLogin,
  createWithAuth,
  roumuCreateRegistration,
  roumuCreateRegistrationInterestedEmail,
  requestResetPassword,
  resetPassword,
  checkResetPasswordToken,
  businessPartnerInput,
}
