import {gql} from '@apollo/client'

export type QueryResult = {
  employeeAlertsOnDashboard: AlertType[]
}

type AlertType = {
  id: string
  name: string
  color: number
  employeeAlerts: EmployeeAlert[]
}

type EmployeeAlert = {
  id: string
  baseDate: string
  employee: Employee
}

type Employee = {
  id: string
  staffCode: string
  displayFirstName: string
  displayLastName: string
}

export const EMPLOYEE_ALERTS_ON_DASHBOARD = gql`
  query employeeAlertsOnDashboard {
    employeeAlertsOnDashboard {
      id
      name
      color
      employeeAlerts {
        id
        baseDate
        employee {
          id
          displayFirstName
          displayLastName
          staffCode
        }
      }
    }
  }
`
