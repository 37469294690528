export const BASIC_BASE_DATE_TYPE_VALUES: string[] = [
  'birthday',
  'age',
  'visa_end_day',
  'transfer_day',
  'joined_day',
  'service_years',
  'retired_day',
  'absence_start_day',
  'absence_end_day',
  'contract_start_day',
  'contract_end_day',
  'welfare_pension_insurance_start_day',
  'welfare_pension_insurance_end_day',
  'health_insurance_start_day',
  'health_insurance_end_day',
  'employment_insurance_start_day',
  'employment_insurance_end_day',
  'qualification_update_day',
  'qualification_end_day',
]

export const BASIC_BASE_DATE_TYPE_LABELS: string[] = [
  '誕生日',
  '年齢',
  '在留期間満了日',
  '異動日',
  '入社日',
  '勤続年数',
  '退職日',
  '休職開始日',
  '休職終了日',
  '契約期間開始日',
  '契約期間終了日',
  '厚生年金保険資格取得日',
  '厚生年金保険資格喪失日',
  '健康保険資格取得日',
  '健康保険資格喪失日',
  '雇用保険資格取得日',
  '雇用保険離職等年月日',
  '資格取得更新年月日',
  '資格有効期限',
]
