import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Delete} from 'jbc-front/components/icons'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import {notifySuccess} from 'store/actions/notify'

import {DELETE_ALERT_TYPE} from '../../query'
import type {Refetch} from '../../index'

import styles from './DeleteColumn.scss'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface DeleteColumnProps {
  alert: {
    id: number
    name: string
  }
  refetch: Refetch
}

export const DeleteColumn: FC<DeleteColumnProps> = ({alert, refetch}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleCloseModal = () => setIsModalOpen(false)
  const handleOpenModal = () => setIsModalOpen(true)

  const dispatch = useDispatch()
  const [deleteAlert, {loading}] = useMutation(DELETE_ALERT_TYPE)

  const handleDeleteClick = async () => {
    await deleteAlert({
      variables: {
        input: {
          id: alert.id,
        },
      },
    })
    setIsModalOpen(false)
    dispatch(notifySuccess('削除しました'))
    refetch()
  }

  return (
    <>
      <Delete size={20} className={styles.icon} onClick={handleOpenModal} />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Modal.Header onClose={handleCloseModal}>削除の確認</Modal.Header>
        <Modal.Body>
          削除されたアラートは復元することができません。
          <br />
          <span className={styles.attention}>本当に{alert.name}を削除しますか</span>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleCloseModal}>いいえ</Button>
            <Button primary onClick={handleDeleteClick} disabled={loading}>
              はい
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  )
}
