import React from 'react'
import Button from 'jbc-front/components/Button'
import {Section, FileField} from 'jbc-front/components/Form'
import {reduxForm, formValueSelector, autofill as autofillForm, destroy as destroyForm} from 'redux-form'
import {connect} from 'react-redux'
import {recordDisplay} from 'utils'
import ListGroup from 'jbc-front/components/ListGroup'
import {bindActionCreators} from 'redux'
import styles from 'procedureStatuses/eGov/PrepareModal.scss'
import {Report} from 'procedureStatuses/reportList/common'
import {show as showPreviewPdf, setPdf} from 'components/PreviewPdf'
import api from 'api'
import {asyncError} from 'store/actions/asyncError'

const formName = 'egovPrepare'
export const selector = formValueSelector(formName)
export const destroy = destroyForm.bind(null, formName)
export const autofill = autofillForm.bind(null, formName)

const mapDispatchToProps = bindActionCreators.bind(null, {autofill})

const SelectReport = connect(
  (state) => ({
    eGovInfomations: state.procedureStatuses.current.data.egov_informations,
    eGovProcedures: state.procedureStatuses.current.data.egov_procedure_statuses,
  }),
  mapDispatchToProps
)(({autofill, eGovInfomations, eGovProcedures}) => {
  const procedureDisableReason = ({procedure_code, errors = []}) => {
    const eGovProcedure = eGovProcedures && eGovProcedures.find(({procedure_code: pc}) => pc == procedure_code)
    if (eGovProcedure && eGovProcedure.status !== 'inadequacy_error') {
      return '既に申請済みです'
    }
    if (errors.length) {
      return (
        <div className={styles.disabledReason}>
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )
    }
    return false
  }
  return (
    <div className={styles.selectBody}>
      <h5>電子申請する書類を選択してください</h5>
      <ListGroup>
        {eGovInfomations.map((info) => {
          const disabled_reason = procedureDisableReason(info)
          const disabled = !!disabled_reason
          return (
            <ListGroup.Item
              as={disabled ? 'div' : 'a'}
              key={info.procedure_code}
              disabled={disabled}
              disabledReason={disabled_reason}
              onClick={
                disabled
                  ? undefined
                  : () => {
                      autofill('_step', 'attachments')
                      autofill('procedure_code', info.procedure_code)
                    }
              }
            >
              {info.name}
              {!disabled && <ListGroup.Icon />}
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </div>
  )
})

const Attachments = connect(
  (state) => ({
    token: state.auth.token,
    procedureStatus: state.procedureStatuses.current.data,
    procedureCode: selector(state, 'procedure_code'),
  }),
  (dispatch) => ({
    async showPreviewPdf(params, procedureStatusId, token) {
      try {
        dispatch(showPreviewPdf(params))
        const {num, dependentId} = params
        const pdf = await api
          .createWithAuth(token)
          .procedureStatuses.reports.getPdf(
            procedureStatusId,
            num,
            dependentId ? {employee_dependent_id: dependentId} : {}
          )
        dispatch(setPdf(pdf))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(({procedureStatus, hideModal, handleSubmit, token, showPreviewPdf, procedureCode, submitting}) => {
  const info = procedureStatus.egov_informations.find((info) => info.procedure_code === procedureCode)
  return (
    <React.Fragment>
      <div className={styles.body}>
        <ListGroup>
          <ListGroup.Title>帳票一覧</ListGroup.Title>
          {info.reports.map((report) => (
            <Report
              key={report.kind}
              onClick={(params) => {
                const {id} = procedureStatus
                const pdfUrl = `/api/procedure_statuses/${id}/pdf?type=${params.num}`
                showPreviewPdf({...params, url: pdfUrl, procedureStatusId: id}, id, token)
              }}
              num={report.kind}
              title={report.name}
              edit
            />
          ))}
        </ListGroup>
        <ListGroup className={styles.listGroup}>
          <ListGroup.Title>対象従業員</ListGroup.Title>
          <ListGroup.Item>{recordDisplay.registeredFullName(procedureStatus.employee)}</ListGroup.Item>
        </ListGroup>
        <Section title="添付書類">
          {info.recommended_attachments &&
            info.recommended_attachments.map((attachment, index) => {
              const {required, display_name, note} = attachment
              return (
                <div key={index} style={note ? {width: 520} : {}}>
                  <FileField
                    name={`attachments[${index}]`}
                    required={required}
                    label={display_name}
                    accept=".jpg,.pdf"
                    note={note && <p className={styles.note}>{note}</p>}
                  />
                </div>
              )
            })}
          <FileField name="attachments_other" multiple label="その他" />
        </Section>
        {procedureStatus.procedure_type === 'enroll' &&
          (info.procedure_kind === 'r1' || info.procedure_kind === 'r2') && (
            <p className={styles.warning}>
              注意：電子申請では従業員情報に登録されている被保険者整理番号は申請されません
            </p>
          )}
      </div>
      <div className={styles.closeBottom}>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={submitting} className="u-ml20">
          申請準備する
        </Button>
      </div>
    </React.Fragment>
  )
})

const ErrorList = connect((state) => ({
  errorList: selector(state, '_errors'),
}))(({errorList = [], hideModal}) => (
  <>
    <div className={styles.body}>
      <Section
        title={
          <div>
            入力内容に誤りがあります。
            <br />
            修正後に再度申請をお願いします
          </div>
        }
        error
      >
        {errorList.map((error, index) => (
          <ValidationError key={index} error={error} />
        ))}
      </Section>
    </div>
    <div className={styles.closeBottom}>
      <Button onClick={hideModal}>閉じる</Button>
    </div>
  </>
))

const ValidationError = ({error: {errorMessage, input = '', ranges = []}}) => {
  const chars = [...input]
  const {inputChunk: inputChunkInit, prevEnd} = ranges.reduce(
    ({inputChunk: prevInputChunk, prevEnd}, {begin, end}) => ({
      inputChunk: [
        ...prevInputChunk,
        {inputSubStr: chars.slice(prevEnd + 1, begin).join(''), highlight: false},
        {inputSubStr: chars.slice(begin, end + 1).join(''), highlight: true},
      ],
      prevEnd: end,
    }),
    {
      inputChunk: [],
      prevEnd: -1,
    }
  )
  const inputChunk = [...inputChunkInit, {inputSubStr: chars.slice(prevEnd + 1).join(''), highlight: false}]
  const highlightedInput = inputChunk.map(({inputSubStr, highlight}, i) => (
    <span key={i} {...(highlight && {className: styles.invalidRangeText})}>
      {inputSubStr}
    </span>
  ))
  return (
    <div className={styles.validationError}>
      <div>{errorMessage}</div>
      {input && <div>入力値：{highlightedInput}</div>}
    </div>
  )
}

const PrepareForm = ({handleSubmit, step, hideModal, submitting}) => (
  <form onSubmit={handleSubmit}>
    {(step === 'attachments' && <Attachments {...{hideModal, handleSubmit, submitting}} />) ||
      (step === 'error_list' && <ErrorList hideModal={hideModal} />) || <SelectReport />}
  </form>
)

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(
  connect((state) => ({
    step: selector(state, '_step'),
  }))(PrepareForm)
)
