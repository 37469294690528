import {FC, useState} from 'react'
import {SearchForm} from './parts/SearchForm/SearchForm'
import {AlertList} from './parts/AlertList/AlertList'
import {
  EMPLOYEE_ALERTS,
  ALERT_TYPES,
  SearchAlertInput,
  SearchAlertQuery,
  AlertTypeQueryResult,
  SearchAlertQueryResult,
  Order,
  SortColumn,
  Search,
} from './query'
import {CsvDownloadModal} from './parts/CsvDownloadModal/CsvDownloadModal'
import {LoadingPage} from 'components/ui/LoadingPage'
import {Tab} from 'components/ui/Tab'
import {Content} from 'components/layout/Content'
import {SearchResult} from 'components/ui/SearchResult'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {useQuery} from 'hooks/graphql'
import moment from 'moment'
import {PerPage} from 'components/ui/PerPage'
import {PaginatorWithResult} from 'jbc-front/components/SearchForm'
import styles from './Presenter.scss'
import classnames from 'classnames'

export const allTimeOfAlertCustomization = {
  alertStartStartOn: moment().subtract(3, 'months').add(1, 'days').format('YYYY/MM/DD'),
  alertStartEndOn: moment().format('YYYY/MM/DD'),
  baseDateStartOn: moment().subtract(3, 'months').add(1, 'days').format('YYYY/MM/DD'),
  baseDateEndOn: moment().format('YYYY/MM/DD'),
}

export const Presenter: FC = () => {
  const [sortOrder, setSortOrder] = useState<Order>('desc')
  const [sortColumn, setSortColumn] = useState<SortColumn>('base_date')
  const defaultQuery = {
    sortColumn: 'base_date',
    sortOrder: 'desc',
    alertStatus: 'in_progress',
    alertStartStartOn: allTimeOfAlertCustomization.alertStartStartOn,
    alertStartEndOn: allTimeOfAlertCustomization.alertStartEndOn,
    baseDateStartOn: allTimeOfAlertCustomization.baseDateStartOn,
    baseDateEndOn: allTimeOfAlertCustomization.baseDateEndOn,
    searchValue: '',
    alertTypeIds: [],
  } as Search
  const [searchQuery, setSearchQuery] = useState<SearchAlertQuery>({
    per: 10,
    page: 1,
    search: defaultQuery,
  })

  const {loading: alertTypesLoading, data: alertTypesData} = useQuery<AlertTypeQueryResult>(ALERT_TYPES)

  const {
    loading: searchLoading,
    data: searchResult,
    refetch,
  } = useQuery<SearchAlertQueryResult, SearchAlertQuery>(EMPLOYEE_ALERTS, {
    fetchPolicy: 'network-only',
    variables: searchQuery,
  })

  const handleSearchInput = (inputData: SearchAlertInput) => {
    const alertStatus =
      inputData.alertStatus === 'in_progress' || inputData.alertStatus === 'completed' ? inputData.alertStatus : null

    const searchData = {
      alertStartStartOn: inputData.alertStartStartOn,
      alertStartEndOn: inputData.alertStartEndOn,
      baseDateStartOn: inputData.baseDateStartOn,
      baseDateEndOn: inputData.baseDateEndOn,
      searchValue: inputData.searchValue,
      alertTypeIds: inputData.alertTypeIds.map((option) => Number(option.value)),
      alertStatus: alertStatus,
    }
    setSearchQuery((prev) => ({
      ...prev,
      page: 1,
      search: {
        ...prev.search,
        ...searchData,
      },
    }))
  }

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      const nextOrder = inverseOrder()
      setSearchQuery((prev) => ({
        ...prev,
        search: {
          ...prev.search,
          sortOrder: nextOrder,
        },
      }))
      setSortOrder(nextOrder)
    } else {
      setSearchQuery((prev) => ({
        ...prev,
        search: {
          ...prev.search,
          sortOrder: 'desc',
          sortColumn: column,
        },
      }))
      setSortOrder('desc')
      setSortColumn(column)
    }
  }

  const inverseOrder = () => {
    switch (sortOrder) {
      case 'desc':
        return 'asc'
      case 'asc':
        return 'desc'
    }
  }

  const resetQuery = () => {
    setSortOrder('desc')
    setSortColumn('base_date')

    setSearchQuery(() => ({
      per: 10,
      page: 1,
      search: defaultQuery,
    }))
  }

  return (
    <div>
      {alertTypesLoading ? (
        <LoadingPage />
      ) : (
        <div>
          <div className={classnames(styles.tabsWrap, 'u-pb0')}>
            <div className={styles.tabs}>
              <Tab to="/system_announces">お知らせ一覧</Tab>
              <Tab to="/notifications">通知一覧</Tab>
              <Tab active>アラート一覧</Tab>
            </div>
          </div>

          <Content size="xxl">
            <div className={styles.csvModalButtonWithSearch}>
              <CsvDownloadModal search={searchQuery.search} defaultQuery={defaultQuery} />
              <SearchForm
                handleSearchInput={handleSearchInput}
                alertTypeList={alertTypesData?.alertTypes?.list ?? []}
                resetQuery={resetQuery}
              />
            </div>
            {searchLoading ? (
              <LoadingPage />
            ) : (
              <div>
                <div className={styles.sortingFieldsWithResult}>
                  <div className={styles.searchResultWrap}>
                    <SearchResult
                      total={searchResult?.employeeAlerts?.totalCount ?? 0}
                      page={searchQuery.page}
                      limit={searchQuery.per}
                    />
                  </div>
                  <div className={styles.perPageWrap}>
                    <PerPage
                      selected={searchQuery.per}
                      onChange={(per) => setSearchQuery((prev) => ({...prev, per, page: 1}))}
                    />
                  </div>
                </div>

                <Spacer direction="y" size={25} />

                <AlertList
                  alerts={searchResult?.employeeAlerts?.list ?? []}
                  onSort={handleSort}
                  order={sortOrder}
                  sortColumn={sortColumn}
                  handleRefetch={refetch}
                />

                <Spacer direction="y" size={20} />

                <PaginatorWithResult
                  limit={searchQuery.per}
                  count={searchResult?.employeeAlerts?.totalCount || 0}
                  page={searchQuery.page}
                  isLoading={alertTypesLoading || searchLoading}
                  onClick={(page) => setSearchQuery((prev) => ({...prev, page}))}
                />
              </div>
            )}
          </Content>
        </div>
      )}
    </div>
  )
}
