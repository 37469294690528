import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {CsvDownloadSearchParams, TargetType} from 'types/api/exportAlertsCsv/exportAlertsCsv'

type Params = {
  target: TargetType
  search: CsvDownloadSearchParams
}

export const useFetchAlertsCount = () => {
  const authAxios = useAuthAxios()

  return async (params: Params) => {
    const alertQuery = params.target === 'search' ? params.search : null
    const requestParams = {alert_query: alertQuery}
    const path = `/api/export_alerts_csv_request/download_count`

    return authAxios.post<number>(path, requestParams)
  }
}
