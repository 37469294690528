import {useQuery} from 'hooks/graphql'
import {QueryResult, EMPLOYEE_ALERTS_ON_DASHBOARD} from './query'
import {Link} from 'react-router-dom'
import styles from './CustomAlerts.scss'
import {dateFormat} from 'libs/formatter'

// @ts-ignore
import ListGroup from 'jbc-front/components/ListGroup'

const MAX_DISPLAY_COUNT = 3
const toColor = (int: number): string => (+int ? `#${(+int).toString(16).padStart(6, '0')}` : '#333333')

export const CustomAlerts = () => {
  const {data: queryResult} = useQuery<QueryResult>(EMPLOYEE_ALERTS_ON_DASHBOARD, {
    fetchPolicy: 'network-only',
  })

  return (
    <div>
      {queryResult?.employeeAlertsOnDashboard.map((alertType) => {
        return (
          <div key={alertType.id} className={alertType.employeeAlerts.length > 0 ? 'u-pt15' : ''}>
            <h4>
              {alertType.name}
              <Link to={'/alerts'} className={styles.alertNumber}>
                （<span className={styles.notParentheses}>{alertType.employeeAlerts.length}件</span>）
              </Link>
            </h4>
            <ul>
              {alertType.employeeAlerts.slice(0, MAX_DISPLAY_COUNT).map((employeeAlert) => (
                <li className={styles.alert} key={employeeAlert.id}>
                  <Link to={`/employees/${employeeAlert.employee.id}`} className={styles.alertLink}>
                    <span className={styles.alertColor} style={{backgroundColor: toColor(alertType.color)}} />
                    <span className={styles.alertDate}>{dateFormat(employeeAlert.baseDate, 'YYYY年MM月DD日')}</span>
                    {employeeAlert.employee.staffCode && (
                      <span className={styles.staffCode}>{employeeAlert.employee.staffCode}</span>
                    )}
                    <span>
                      {employeeAlert.employee.displayLastName} {employeeAlert.employee.displayFirstName}
                    </span>
                    <ListGroup.Icon />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}
