import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'
import {CsvDownloadSearchParams, TargetType, EncodingType} from 'types/api/exportAlertsCsv/exportAlertsCsv'

type Params = {
  target: TargetType
  encoding: EncodingType
  search: CsvDownloadSearchParams
}

export const useDownloadAlertsCsv = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (params: Params) => {
    const alertQuery = params.target === 'search' ? params.search : null
    const requestParams = {
      encoding: params.encoding,
      alert_query: alertQuery,
    }
    const path = `/api/export_alerts_csv`
    const getter = () => authAxios.post(path, requestParams, {responseType: 'arraybuffer'})

    download(getter)
  }
}
