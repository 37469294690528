import {gql} from '@apollo/client'
import {AlertTypeInput} from './query'
import {BASIC_BASE_DATE_TYPE_VALUES} from 'consts/alertCustom'

interface MutationAlertType {
  id: string
  color: number
  name: string
  description: string
  baseDateType: string
  offset: number
  days: number
  dateUnit: string
  active: boolean
  customEmployeeFieldId: string | null
  adminMailActive: boolean
  adminMailSubject: string
  adminMailBody: string
  employeeMailActive: boolean
  employeeMailSubject: string
  employeeMailBody: string
  destroyEmployeeAlerts: boolean
}

export const convertFormDataToMutationAlertType = (
  alertTypeInput: AlertTypeInput,
  id: string,
  isDestroy = false
): MutationAlertType => {
  const days = alertTypeInput.isBefore === 'Before' ? -Number(alertTypeInput.days) : Number(alertTypeInput.days)
  const baseDateType = BASIC_BASE_DATE_TYPE_VALUES.includes(alertTypeInput.baseDateType.value)
    ? alertTypeInput.baseDateType.value
    : 'custom_value'
  const customEmployeeFieldId = baseDateType === 'custom_value' ? alertTypeInput.baseDateType.value : null
  const offset = baseDateType === 'age' || baseDateType === 'service_years' ? Number(alertTypeInput.offset) : 0

  const mutationAlertType: MutationAlertType = {
    id: id,
    color: Number(alertTypeInput.color),
    name: alertTypeInput.name,
    description: alertTypeInput.description,
    baseDateType: baseDateType,
    offset: offset ?? 0,
    days: days,
    dateUnit: alertTypeInput.dateUnit,
    active: alertTypeInput.active,
    customEmployeeFieldId: customEmployeeFieldId,
    adminMailActive: alertTypeInput.adminMailActive,
    adminMailSubject: alertTypeInput.adminMailSubject ?? '',
    adminMailBody: alertTypeInput.adminMailBody ?? '',
    employeeMailActive: alertTypeInput.employeeMailActive,
    employeeMailSubject: alertTypeInput.employeeMailSubject ?? '',
    employeeMailBody: alertTypeInput.employeeMailBody ?? '',
    destroyEmployeeAlerts: isDestroy,
  }
  return mutationAlertType
}

export const isChangeNotificationDate = (defaultValues: AlertTypeInput | undefined, formValues: AlertTypeInput) => {
  const isBaseDateTypeEqual = defaultValues?.baseDateType.value === formValues.baseDateType.value
  const isOffsetEqual = defaultValues?.offset === formValues.offset
  const isDateUnitEqual = defaultValues?.dateUnit === formValues.dateUnit
  const isDaysEqual = defaultValues?.days === formValues.days
  const isBeforeEqual = defaultValues?.isBefore === formValues.isBefore

  return !(isBaseDateTypeEqual && isOffsetEqual && isDateUnitEqual && isDaysEqual && isBeforeEqual)
}

export const UPDATE_ALERT_TYPE = gql`
  mutation ($input: UpdateAlertInput!) {
    updateAlertType(input: $input) {
      alertType {
        id
        color
        name
        description
        clientId
        alertTypeNotifications {
          destination
          subject
          body
          active
        }
      }
    }
  }
`
