import {FC, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {SearchAlertInput, convertToOption, AlertTypeOption} from '../../query'
import styles from './SearchForm.scss'
import {DateRow} from './DateRow'
import {MultiSelect, Option} from 'jbc-front/components/presenters/form/Select'
import ActionButton from 'jbc-front/components/ActionButton'
import Tag from 'jbc-front/components/Tag'
import {Input} from 'jbc-front/components/presenters/form/Input'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {useKeywordInput} from 'hooks/useKeywordInput'
import {yupResolver} from '@hookform/resolvers/yup'
import {schema} from '../../schema'
import {allTimeOfAlertCustomization} from '../../Presenter'

type SearchFormProps = {
  handleSearchInput: (data: SearchAlertInput) => void
  alertTypeList: AlertTypeOption[]
  resetQuery: () => void
}

type FilterStatusKey = 'all' | 'in_progress' | 'completed'
type FilterStatuses = {key: FilterStatusKey; label: string}[]
const statuses: FilterStatuses = [
  {key: 'all', label: 'すべて'},
  {key: 'in_progress', label: '進行中'},
  {key: 'completed', label: '完了'},
]

export const SearchForm: FC<SearchFormProps> = ({handleSearchInput, alertTypeList, resetQuery}) => {
  const convertedOptions = convertToOption(alertTypeList)
  const [searchValue, setSearchValue] = useState<string>('')
  const [activeFilter, setActiveFilter] = useState<FilterStatusKey>('in_progress')
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<SearchAlertInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      searchValue: '',
      alertTypeIds: [],
      alertStatus: 'in_progress',
      alertStartStartOn: allTimeOfAlertCustomization.alertStartStartOn,
      alertStartEndOn: allTimeOfAlertCustomization.alertStartEndOn,
      baseDateStartOn: allTimeOfAlertCustomization.baseDateStartOn,
      baseDateEndOn: allTimeOfAlertCustomization.baseDateEndOn,
    },
  })

  const onSubmit = (data: SearchAlertInput) => {
    handleSearchInput(data)
  }

  const autoSubmit = handleSubmit(onSubmit)

  const resetForm = () => {
    reset()
    setActiveFilter('in_progress')
    setSearchValue('')
    resetQuery()
  }

  const handleKeywordInput = (value: string) => {
    setValue('searchValue', value)
    autoSubmit()
  }

  const [, keywordSubject] = useKeywordInput(handleKeywordInput)

  return (
    <div className={styles.searchForm}>
      <form>
        <div className={styles.mainSearchFields}>
          <div className={styles.mainFormControls}>
            <div className={styles.mainFormControl}>
              <Input
                value={searchValue}
                onChange={(e) => {
                  keywordSubject.next(e.target.value)
                  setSearchValue(e.target.value)
                }}
                style={{width: 230, height: 30}}
                placeholder="氏名、スタッフコード"
              />
            </div>
            <ActionButton onClick={() => resetForm()}>条件解除</ActionButton>
          </div>
        </div>

        <div className={styles.details}>
          <div className={styles.detailsRow}>
            <div className={styles.detailsHeader}>ステータス</div>
            <div className={styles.detailsBody}>
              <div className={styles.detailsBodyItems}>
                {statuses.map((status) => (
                  <Tag
                    key={status.key}
                    active={activeFilter === status.key}
                    onClick={() => {
                      setActiveFilter(status.key)
                      setValue('alertStatus', status.key)
                      autoSubmit()
                    }}
                  >
                    {status.label}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
          <Spacer direction="y" size={1} />

          <div className={styles.detailsRow}>
            <div className={styles.detailsHeader}>アラート名</div>
            <div className={styles.detailsBody}>
              <div className={styles.alertTypeSelect}>
                <Controller
                  name="alertTypeIds"
                  control={control}
                  render={({field}) => (
                    <MultiSelect
                      options={convertedOptions}
                      value={field.value}
                      onChange={(val: Option[]) => {
                        field.onChange(val)
                        autoSubmit()
                      }}
                      noOptionsMessage={() => 'データが見つかりません'}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <Spacer direction="y" size={1} />

          <DateRow
            header="通知日"
            names={['alertStartStartOn', 'alertStartEndOn']}
            control={control}
            autoSubmit={autoSubmit}
            errorMessage={errors?.alertStartStartOn?.message || errors?.alertStartEndOn?.message}
            setValue={setValue}
          />
          <Spacer direction="y" size={1} />

          <DateRow
            header="基準日"
            names={['baseDateStartOn', 'baseDateEndOn']}
            control={control}
            autoSubmit={autoSubmit}
            errorMessage={errors?.baseDateStartOn?.message || errors?.baseDateEndOn?.message}
            setValue={setValue}
          />
        </div>
      </form>
    </div>
  )
}
