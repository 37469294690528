import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Show} from 'pages/withAuth/adminPage/settings/alertCustom/show'
import {Create} from 'pages/withAuth/adminPage/settings/alertCustom/create'
import {Edit} from 'pages/withAuth/adminPage/settings/alertCustom/edit'

export const alertCustomRoutes: RouteParams[] = [
  {
    key: 'settings/alert_custom',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.ALERT_CUSTOM.SHOW,
    component: Show,
    exact: true,
    whiteList: ['full_admin', 'office_admin'],
  },
  {
    key: 'settings/alert_custom/create',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.ALERT_CUSTOM.CREATE,
    component: Create,
    exact: true,
    whiteList: ['full_admin', 'office_admin'],
  },
  {
    key: 'settings/alert_custom/edit',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.ALERT_CUSTOM.EDIT,
    component: Edit,
    exact: true,
    whiteList: ['full_admin', 'office_admin'],
  },
]
