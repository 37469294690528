import {FC} from 'react'
import {SettingItem} from './parts/SettingItem'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {MainTitle} from 'components/layout/MainTitle'
import {Content} from 'components/layout/Content'
import styles from './Presenter.scss'

interface PresenterProps {
  isOfficeAdmin: boolean
}

export const Presenter: FC<PresenterProps> = ({isOfficeAdmin}) => {
  return (
    <>
      <MainTitle title="設定" />
      <Content size="l">
        <div className={styles.main}>
          <section className={styles.section}>
            <h3 className={styles.category}>事業所情報</h3>
            <ul className={styles.list}>
              <SettingItem to="/client" title="法人情報">
                法人（本社）の事業所情報を設定できます
              </SettingItem>
              <SettingItem to="/offices" title="適用事業所設定">
                適用事業所の設定ができます
                <span className={styles.subText}>
                  ※保険情報の異なる複数の事業所を管理することができます。従業員の手続きには所属している適用事業所の情報が使用されます。従業員のグループとは関係ありません。
                </span>
              </SettingItem>
              <SettingItem to={WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.SHOW} title="電子申請情報">
                電子申請の情報を設定できます
              </SettingItem>
              <SettingItem to={WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.ACCOUNT.SHOW} title="e-Govアカウント連携">
                e-Govアカウントの連携ができます
              </SettingItem>
              <SettingItem to="/settings/labor_consultant" title="社労士情報" disabled={isOfficeAdmin}>
                社会保険労務士の情報を設定できます
              </SettingItem>
            </ul>
          </section>

          <section className={styles.section}>
            <h3 className={styles.category}>組織管理</h3>
            <ul className={styles.list}>
              <SettingItem to="/settings/employment_types" title="雇用形態">
                雇用形態の設定ができます
              </SettingItem>
              <SettingItem to="/settings/occupations" title="職種">
                職種の設定ができます
              </SettingItem>
              <SettingItem to="/settings/groups" title="グループ">
                部署やグループの設定ができます
              </SettingItem>
              <SettingItem to="/settings/positions" title="役職">
                役職の設定ができます
              </SettingItem>
            </ul>
          </section>
          <section className={styles.section}>
            <h3 className={styles.category}>サービス設定</h3>
            <ul className={styles.list}>
              <SettingItem to="/settings/using_plan" title="利用プラン" disabled={isOfficeAdmin}>
                現在のプランと請求情報を確認できます
              </SettingItem>
              <SettingItem to="/settings/coordination" title="サービス連携" disabled={isOfficeAdmin}>
                ジョブカンとの連携状態を確認できます
              </SettingItem>
              <SettingItem to="/settings/client_roles" title="権限管理設定" disabled={isOfficeAdmin}>
                権限の変更ができます
              </SettingItem>
              <SettingItem to="/settings/notifications" title="通知先設定">
                メールでの通知先を設定できます
              </SettingItem>
              <SettingItem to="/settings/audit_logs" title="操作履歴">
                変更履歴を確認できます
              </SettingItem>
              <SettingItem to="/settings/allowed_ips_other" title="IPアドレス制限" disabled={isOfficeAdmin}>
                接続元として制限するIPアドレスを設定できます
              </SettingItem>
              <SettingItem to="/settings/client_files" title="ファイル共有">
                社内規定ファイルをアップロード、従業員が記入したファイルをダウンロードできます
              </SettingItem>
            </ul>
          </section>
          <section className={styles.section}>
            <h3 className={styles.category}>カスタマイズ</h3>
            <ul className={styles.list}>
              <SettingItem to="/settings/custom_mails" title="メールカスタマイズ">
                メールの内容をカスタマイズできます
              </SettingItem>
              <SettingItem to="/settings/employee_custom" title="従業員項目カスタマイズ">
                従業員情報の項目をカスタマイズできます
              </SettingItem>
              <SettingItem to="/settings/alert_custom" title="アラートカスタマイズ">
                従業員情報の項目にアラートの設定を行うことができます
              </SettingItem>
            </ul>
          </section>
          <section className={styles.section}>
            <h3 className={styles.category}>人材管理設定</h3>
            <ul className={styles.list}>
              <SettingItem to="/settings/talent_display" title="情報公開設定" disabled={isOfficeAdmin}>
                「人材管理」で表示する従業員・表示する項目を設定することができます
              </SettingItem>
              <SettingItem
                to="/settings/custom_employee_field_group_icons"
                title="アイコン設定"
                disabled={isOfficeAdmin}
              >
                従業員詳細画面で表示する項目のアイコンを設定することができます
              </SettingItem>
            </ul>
          </section>
        </div>
      </Content>
    </>
  )
}
