import {FC} from 'react'
import {Link} from 'react-router-dom'
import {LoadingPage} from 'components/ui/LoadingPage'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import {AlertTypeInput, QueryResult, CUSTOM_EMPLOYEE_FIELDS} from './query'
import {convertFormDataToMutationAlertType, CREATE_ALERT_TYPE} from './mutation'
import {AlertTypeCreateForm} from './parts/AlertTypeCreateForm'
import {Content} from 'components/layout/Content'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {useQuery} from 'hooks/graphql'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export const Presenter: FC = () => {
  const dispatch = useDispatch()
  const {loading: queryLoading, data: queryData} = useQuery<QueryResult>(CUSTOM_EMPLOYEE_FIELDS, {
    fetchPolicy: 'cache-and-network',
  })
  const [createAlertType, {loading: mutationLoading}] = useMutation(CREATE_ALERT_TYPE)

  const onSubmit = async (data: AlertTypeInput) => {
    try {
      await createAlertType({variables: {input: convertFormDataToMutationAlertType(data)}})
      dispatch(push('/settings/alert_custom'))
      dispatch(notifySuccess('作成しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">アラートカスタマイズ</h1>
        <p className="m-title-main-note">従業員情報の項目にアラートの設定を行うことができます</p>
      </div>
      <Content size="xxl">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/alert_custom" className="l-breadcrumb-link">
            アラートカスタマイズ
          </Link>
          <span className="l-breadcrumb-here">編集</span>
        </div>
        {queryLoading ? (
          <LoadingPage />
        ) : (
          <AlertTypeCreateForm
            onSubmit={onSubmit}
            loading={mutationLoading}
            customFields={queryData?.customEmployeeFieldGroups ?? []}
          />
        )}
      </Content>
    </div>
  )
}
