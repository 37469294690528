import React, {Component} from 'react'
import {TextAreaField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import {reduxForm} from 'redux-form'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {actionCreators} from 'actions'
import api from 'api'
import {parse} from 'query-string'
import {getState} from 'utils'
import {push} from 'connected-react-router'
import styles from 'procedures/resign/Request.scss'
import EmployeeName from 'procedures/EmployeeName'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Request extends Component {
  componentDidMount() {
    const {
      location: {search},
      token,
      loadEmployee,
    } = this.props
    const {employee_id: id} = parse(search)
    loadEmployee(token, id)
  }

  componentWillUnmount() {
    this.props.destroyEmployee()
  }

  render() {
    const {
      submitting,
      handleSubmit,
      location: {search},
      employee,
    } = this.props
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">以下の情報を従業員に入力依頼できます</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="l-wrap-s">
          <div className={styles.box}>
            <ul className={styles.list}>
              <li>最終出勤日</li>
              <li>退職日</li>
              <li>退職理由</li>
              <li>退職後の送付先住所</li>
              <li>退職後の連絡用メールアドレス</li>
              <li>退職後の連絡用電話番号</li>
              <li>源泉徴収票の渡し方（従業員は「メール」か「郵送」か選択できます）</li>
              <li>住民税の徴収方法</li>
              <li>離職票の希望</li>
              <li>退職後の保険加入の確認</li>
              <li>資格確認書等の返却日</li>
              <li>資格確認書等の紛失状況</li>
              <li>次の勤務先名称</li>
              <li>次の勤務先入社日</li>
              <li>備考</li>
            </ul>
            <p className={styles.notes}>※ 最終出勤日と退職日以外は任意の入力項目になります</p>
          </div>

          <div className={styles.formWrap}>
            <form>
              <TextAreaField
                name="message"
                label="メッセージ"
                placeholder="入力したメッセージは送信メールに反映されます"
              />
              <div className={styles.buttonWrap}>
                <Button disabled={submitting} as={Link} to={`/resign/detail${search}`}>
                  スキップ
                </Button>
                <div className={styles.buttonNoteWrap}>
                  <Button
                    primary
                    onClick={handleSubmit}
                    disabled={submitting || !employee.user || !employee.user.email}
                    className="u-ml20"
                  >
                    上記の入力依頼を送信
                  </Button>
                  {(!employee.user || !employee.user.email) && (
                    <div className={styles.noteWrap}>
                      <div className={styles.note}>
                        <p>
                          従業員に退社の手続き入力依頼をする場合、あらかじめ従業員招待をしてマイページを発行してください。従業員招待は
                          <Link to="/employees/invite">こちら</Link>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    employee: state.employees.current.data,
  }),
  (dispatch, ownProps) => ({
    loadEmployee(token, id) {
      dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.get(id, ['user'])))
    },
    destroyEmployee() {
      dispatch(actionCreators.employees.current.destroy())
    },
    async onSubmit(values) {
      try {
        const {
          auth: {token},
        } = await getState(dispatch)
        const {
          location: {search},
        } = ownProps
        const {employee_id: employeeId} = parse(search)
        const authedApi = api.createWithAuth(token)
        const {
          data: {id},
        } = await authedApi.requestProcedure(employeeId, 'resign', values.message)
        dispatch(notifySuccess('依頼しました'))
        dispatch(push(`/resign/detail/${id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(
  reduxForm({
    form: 'resignRequest',
  })(Request)
)
