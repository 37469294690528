import React, {useState} from 'react'
import {Section, CheckboxField, DateField, RadioField, TextField} from 'jbc-front/components/Form'
import {toFormValues, recordDisplay} from 'utils'
import connectForm from 'reportForms/connectForm'
import {reduxForm, FormSection, formValueSelector} from 'redux-form'
import {Notes, SubmitedOn, NeedIssueCertificateCheck} from 'FormFields'
import Button from 'jbc-front/components/Button'
import {MyNumber} from 'reportForms/common'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import _ from 'lodash'
import EmployeeName from 'procedures/EmployeeName'
import styles from './R4Report.scss'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R4Report'
const selector = formValueSelector(formName)

const codeFormat = (value) =>
  value && !value.match(/^(0[1-9]|[1-9][0-9])$/) ? 'は01〜99までの数字を入力してください' : undefined

const KyokaiHealthInsurance = ({employee, dependents, procedureStatus}) => {
  const r4Dependents = getRelatedDependents(dependents, procedureStatus)

  return (
    <React.Fragment>
      {r4Dependents &&
        r4Dependents.map((dependent, index) => {
          return (
            <FormSection name={`r4_dependent_param_set[${index}]`} key={dependent.id}>
              <Section title={`${dependent.last_name} ${dependent.first_name}さんの情報`}>
                <CheckboxField
                  name="relation_type_checked"
                  label="事業主は、被保険者と被扶養者の続柄を確認しました。"
                  note="事業主が戸籍謄本等で被保険者と扶養認定を受ける方の続柄を確認した場合はチェックを入れてください。"
                />
                {dependent.relation_type === 'spouse' && (
                  <CheckboxField
                    name="delegated"
                    label="第３号被保険者関係届の提出は配偶者（第２号被保険者）に委任します"
                  />
                )}
                <Notes />
                {['enroll', 'add_dependents'].includes(procedureStatus.procedure_type) && <NeedIssueCertificateCheck />}
              </Section>
            </FormSection>
          )
        })}
      <MyNumber employee={employee} dependents={dependents} />
      <CheckboxField
        name="confirmed"
        label="収入に関する証明の添付が省略されている者は、所得税法上の控除対象配偶者・扶養親族であることを確認しました。"
        note="所得税法により規定されている控除対象配偶者、扶養親族となっていることを事業主が確認した場合はチェックを入れてください。"
      />
      <DateField name="accept_on" label="事業主等受付年月日" />
    </React.Fragment>
  )
}

const OtherHealthInsurance = ({employee, dependents, procedureStatus}) => {
  const r4Dependents = getRelatedDependents(dependents, procedureStatus)

  return (
    <React.Fragment>
      {r4Dependents &&
        r4Dependents.map((dependent, index) => {
          return (
            <FormSection name={`r4_dependent_param_set[${index}]`} key={dependent.id}>
              <Section title={`${dependent.last_name} ${dependent.first_name}さんの情報`}>
                <div className={styles.itemBox}>
                  {['enroll', 'add_dependents'].includes(procedureStatus.procedure_type) && (
                    <RadioField
                      label="再認定種別"
                      options={[
                        {value: 'first_time', label: '新規認定'},
                        {value: 'again', label: '再認定'},
                      ]}
                      name="certification_type"
                    />
                  )}
                  <TextField name="relation_code" label="続柄" validate={codeFormat} />
                  <div className={styles.description}>健保組合指定の番号を入力してください</div>
                  <TextField name="reason_code" label="該当・非該当事由" validate={codeFormat} />
                  <div className={styles.description}>健保組合指定の番号を入力してください</div>
                </div>

                <CheckboxField
                  name="relation_type_checked"
                  label="事業主は、被保険者と被扶養者の続柄を確認しました。"
                  note="事業主が戸籍謄本等で被保険者と扶養認定を受ける方の続柄を確認した場合はチェックを入れてください。"
                />
                {dependent.relation_type === 'spouse' && (
                  <CheckboxField
                    name="delegated"
                    label="第３号被保険者関係届の提出は配偶者（第２号被保険者）に委任します"
                  />
                )}
                <Notes />
                {['enroll', 'add_dependents'].includes(procedureStatus.procedure_type) && <NeedIssueCertificateCheck />}
              </Section>
            </FormSection>
          )
        })}
      <Section title={`${recordDisplay.fullName(employee)}さんの情報（本人）`}>
        <Notes />
      </Section>
      <div className="u-mb40">
        <MyNumber employee={employee} />
      </div>
      <CheckboxField
        name="confirmed"
        label="収入に関する証明の添付が省略されている者は、所得税法上の控除対象配偶者・扶養親族であることを確認しました。"
        note="所得税法により規定されている控除対象配偶者、扶養親族となっていることを事業主が確認した場合はチェックを入れてください。"
      />
      <DateField name="accept_on" label="事業主等受付年月日" />
    </React.Fragment>
  )
}

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({handleSubmit, submitting, submitText, employee, dependents, procedureStatus, healthInsuranceType, needUpgrade}) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">健康保険被扶養者（異動）届</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section>
            <LabelMapper name="r4_dependent_param_set" label="扶養情報" />
            {healthInsuranceType === 'kyokai' ? (
              <KyokaiHealthInsurance employee={employee} dependents={dependents} procedureStatus={procedureStatus} />
            ) : (
              <OtherHealthInsurance employee={employee} dependents={dependents} procedureStatus={procedureStatus} />
            )}
            <SubmitedOn />
            <div className="u-ta-c u-mt30">
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => handleSubmit()}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const makeInitialValues = ({dependents, healthInsuranceType, data: reportValues, procedureStatus}) => {
  if (!dependents) {
    return toFormValues(reportValues)
  }
  const r4DependentParamSet = _.get(reportValues, 'r4_dependent_param_set') || []
  const r4Dependents = getRelatedDependents(dependents, procedureStatus)
  const r4DependentValues = r4Dependents.map((dependent) =>
    toFormValues(
      _.find(r4DependentParamSet, (value) => value.employee_dependent_id == dependent.id) || {
        employee_dependent_id: dependent.id,
        certification_type: healthInsuranceType === 'other' ? 'first_time' : null,
      }
    )
  )
  return _.assign(toFormValues(reportValues), {r4_dependent_param_set: r4DependentValues})
}

const connectedForm = connectForm('r4', Form, {
  mapState: (state) => ({
    dependents: _.get(state, 'procedureStatuses.current.data.employee.employee_dependents'),
    employee: _.get(state, 'procedureStatuses.current.data.employee') || {},
    healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
    procedureStatus: state.procedureStatuses.current.data,
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  makeInitialValues,
})

export default connectedForm
