import {gql} from '@apollo/client'

export type AlertStatus = 'in_progress' | 'completed'

export type QueryVariables = {
  employeeId: string
  alertStatus: AlertStatus | null
}

type AlertType = {
  name: string
  description: string
  color: number
}

type EmployeeAlert = {
  id: string
  baseDate: string
  alertStatus: AlertStatus
  alertType: AlertType
}

export type QueryResult = {
  employeeAlert: EmployeeAlert[]
}

export type HasActiveQueryVariables = {
  employeeId: string
}

export type HasActiveQueryResult = {
  hasActiveEmployeeAlert: boolean
}

export const HAS_ACTIVE_EMPLOYEE_ALERT = gql`
  query hasActiveEmployeeAlert($employeeId: ID!) {
    hasActiveEmployeeAlert(employeeId: $employeeId)
  }
`

export const EMPLOYEE_ALERT = gql`
  query employeeAlert($employeeId: ID!, $alertStatus: AlertStatus) {
    employeeAlert(employeeId: $employeeId, alertStatus: $alertStatus) {
      id
      baseDate
      alertStatus
      alertType {
        color
        name
        description
      }
    }
  }
`

export const COMPLETE_EMPLOYEE_ALERT = gql`
  mutation CompleteEmployeeAlert($input: CompleteEmployeeAlertInput!) {
    completeEmployeeAlert(input: $input) {
      employeeAlert {
        id
      }
    }
  }
`
