import {FC} from 'react'
import {Edit} from 'jbc-front/components/icons'
import {Link} from 'react-router-dom'

import type {AlertType} from '../../query'

interface DeleteColumnProps {
  alert: AlertType
}

export const EditColumn: FC<DeleteColumnProps> = ({alert}) => {
  return (
    <Link to={`/settings/alert_custom/edit/${alert.id}`}>
      <Edit size={20} />
    </Link>
  )
}
