import {gql} from '@apollo/client'
import {Option} from 'jbc-front/components/presenters/form/Select'

interface CustomEmployeeField {
  id: string
  label: string
}

export interface CustomEmployeeFieldGroup {
  label: string
  customEmployeeFields: CustomEmployeeField[]
}

export interface QueryResult {
  customEmployeeFieldGroups: CustomEmployeeFieldGroup[]
}

export interface AlertTypeInput {
  color: number
  name: string
  description: string
  baseDateType: Option
  offset?: string
  isBefore: 'Before' | 'After'
  days: string
  dateUnit: string
  active: boolean
  adminMailActive: boolean
  adminMailSubject?: string
  adminMailBody?: string
  employeeMailActive: boolean
  employeeMailSubject?: string
  employeeMailBody?: string
}

export const convertFieldsToCustomValues = (customEmployeeFieldGroups: CustomEmployeeFieldGroup[]): Option[] => {
  return customEmployeeFieldGroups.flatMap((group) =>
    group.customEmployeeFields.map((field) => ({
      value: field.id,
      label: `${group.label}/${field.label}`,
    }))
  )
}

export const CUSTOM_EMPLOYEE_FIELDS = gql`
  query customEmployeeFields {
    customEmployeeFieldGroups {
      label
      customEmployeeFields(fieldType: date) {
        id
        label
      }
    }
  }
`
