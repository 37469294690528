import {gql} from '@apollo/client'
import {Option} from 'jbc-front/components/presenters/form/Select'

interface CustomEmployeeField {
  id: string
  label: string
}

export interface CustomEmployeeFieldGroup {
  label: string
  customEmployeeFields: CustomEmployeeField[]
}

export interface CustomEmployeeFieldGroupQuery {
  customEmployeeFieldGroups: CustomEmployeeFieldGroup[]
}

interface AlertTypeCustomDate {
  customEmployeeFieldId: number
}

interface AlertTypeNotification {
  active: boolean
  destination: 'admin' | 'employee'
  subject: string
  body: string
}

export interface AlertType {
  id: string
  color: number
  name: string
  description: string
  baseDateType: string
  offset: number
  days: number
  dateUnit: string
  active: boolean
  alertTypeCustomDate?: AlertTypeCustomDate
  alertTypeNotifications: [AlertTypeNotification, AlertTypeNotification]
}

export interface AlertTypeQuery {
  alertType: AlertType
}

export interface AlertTypeInput {
  color: number
  name: string
  description: string
  baseDateType: Option
  offset?: string
  isBefore: 'Before' | 'After'
  days: string
  dateUnit: string
  active: boolean
  adminMailActive: boolean
  adminMailSubject?: string
  adminMailBody?: string
  employeeMailActive: boolean
  employeeMailSubject?: string
  employeeMailBody?: string
}

export const convertFieldsToCustomValues = (customEmployeeFieldGroups: CustomEmployeeFieldGroup[]): Option[] => {
  return customEmployeeFieldGroups.flatMap((group) =>
    group.customEmployeeFields.map((field) => ({
      value: field.id,
      label: `${group.label}/${field.label}`,
    }))
  )
}

export const ConvertQueryAlertTypeToFormData = (alertType: AlertType, options: Option[]): AlertTypeInput => {
  const isBefore = alertType.days < 0 ? 'Before' : 'After'
  const days = alertType.days > 0 ? alertType.days : -alertType.days

  const defaultOption: Option = {label: '生年月日', value: 'birthday'}
  const baseDateType: Option = alertType?.alertTypeCustomDate?.customEmployeeFieldId
    ? options.find((option) => option.value === alertType?.alertTypeCustomDate?.customEmployeeFieldId.toString()) ||
      defaultOption
    : options.find((option) => option.value === alertType.baseDateType) || defaultOption

  const adminMail = alertType.alertTypeNotifications.find((atn) => atn.destination === 'admin')
  const employeeMail = alertType.alertTypeNotifications.find((atn) => atn.destination === 'employee')

  const alertTypeInput: AlertTypeInput = {
    color: alertType.color,
    name: alertType.name,
    description: alertType.description,
    baseDateType: baseDateType,
    offset: String(alertType.offset),
    isBefore: isBefore,
    days: String(days),
    dateUnit: alertType.dateUnit,
    active: alertType.active,
    adminMailActive: adminMail?.active ?? false,
    adminMailSubject: adminMail?.subject ?? '',
    adminMailBody: adminMail?.body ?? '',
    employeeMailActive: employeeMail?.active ?? false,
    employeeMailSubject: employeeMail?.subject ?? '',
    employeeMailBody: employeeMail?.body ?? '',
  }
  return alertTypeInput
}

export const CUSTOM_EMPLOYEE_FIELDS = gql`
  query customEmployeeFields {
    customEmployeeFieldGroups {
      label
      customEmployeeFields(fieldType: date) {
        id
        label
      }
    }
  }
`

export const ALERT_TYPE = gql`
  query alertType($id: ID!) {
    alertType(id: $id) {
      id
      color
      name
      clientId
      description
      baseDateType
      offset
      days
      dateUnit
      active
      alertTypeCustomDate {
        customEmployeeFieldId
      }
      alertTypeNotifications {
        destination
        subject
        body
        active
      }
    }
  }
`
