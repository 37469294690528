import * as yup from 'yup'
import moment from 'moment'

const validateDate = (startOn?: string, endOn?: string) => {
  return [startOn, endOn].every(
    (date) => date && /^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(date) && moment(date, 'YYYY/MM/DD').isValid()
  )
}

export const schema = yup.object().shape({
  alertStartStartOn: yup
    .string()
    .test('alert-validation', '日付を入力してください', function (alertStartOn) {
      const {alertStartEndOn} = this.parent
      return validateDate(alertStartOn, alertStartEndOn)
    })
    .test('alertStart-validation', '最大3ヶ月間の情報を検索できます', function (value) {
      const {alertStartEndOn} = this.parent
      if (moment(alertStartEndOn).isAfter(moment(value).add(3, 'months').subtract(1, 'days'))) {
        return false
      }
      return true
    })
    .test('alertStart-range-validation', '日付の範囲が正しくありません', function (value) {
      const {alertStartEndOn} = this.parent
      return moment(value, 'YYYY/MM/DD').diff(moment(alertStartEndOn, 'YYYY/MM/DD'), 'days') <= 0
    }),
  baseDateStartOn: yup
    .string()
    .test('alert-validation', '日付を入力してください', function (baseDateStartOn) {
      const {baseDateEndOn} = this.parent
      return validateDate(baseDateStartOn, baseDateEndOn)
    })
    .test('baseDate-validation', '最大3ヶ月間の情報を検索できます', function (value) {
      const {baseDateEndOn} = this.parent
      if (moment(baseDateEndOn).isAfter(moment(value).add(3, 'months').subtract(1, 'days'))) {
        return false
      }
      return true
    })
    .test('baseDate-range-validation', '日付の範囲が正しくありません', function (value) {
      const {baseDateEndOn} = this.parent
      return moment(value, 'YYYY/MM/DD').diff(moment(baseDateEndOn, 'YYYY/MM/DD'), 'days') <= 0
    }),
})
