import {FC} from 'react'
import {Controller, Control} from 'react-hook-form'
import {AlertTypeInput} from '../query'

// @ts-ignore
import {EmployeeFroalaEditorField} from '../../froala/lazy'

type MailBodyProps = {
  name: 'adminMailBody' | 'employeeMailBody'
  control: Control<AlertTypeInput>
}

export const EmployeeMailBody: FC<MailBodyProps> = ({name, control}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <EmployeeFroalaEditorField
          input={{
            value: field.value,
            onChange: field.onChange,
          }}
        />
      )}
    />
  )
}
