import {FC, useState, useEffect} from 'react'
import {SortableContainer, arrayMove} from 'react-sortable-hoc'

import {Table} from 'components/ui/Table'
import {Row, SortableRow} from './Row'

import styles from './List.scss'

import type {AlertType} from '../../query'
import type {Refetch} from '../../index'

interface ListProps {
  alertTypes: AlertType[]
  isEditing: boolean
  setOrdinals: (ids: number[], ordinals: number[]) => void
  refetch: Refetch
  page: number
  limit: number
}

interface SortableAlertTypeTableProps {
  sortedAlertTypes: AlertType[]
  refetch: Refetch
}

interface AlertTypeTableProps {
  alertTypes: AlertType[]
  refetch: Refetch
  firstIndex: number
}

interface OnSortEndParams {
  oldIndex: number
  newIndex: number
}

const SortableAlertTypeTable = SortableContainer(({sortedAlertTypes, refetch}: SortableAlertTypeTableProps) => {
  return (
    <Table.Tbody>
      {sortedAlertTypes.map((alertType, index) => (
        <SortableRow key={alertType.id} index={index} alert={alertType} refetch={refetch} />
      ))}
    </Table.Tbody>
  )
})

const AlertTypeTable: FC<AlertTypeTableProps> = ({alertTypes, refetch, firstIndex}) => {
  return (
    <Table.Tbody>
      {alertTypes.map((alertType, index) => (
        <Row key={alertType.id} index={firstIndex + index} alert={alertType} refetch={refetch} />
      ))}
    </Table.Tbody>
  )
}

export const List: FC<ListProps> = ({alertTypes, isEditing, setOrdinals, refetch, page, limit}) => {
  const [sortedAlertTypes, setSortedAlertTypes] = useState<AlertType[]>([])

  useEffect(() => {
    if (alertTypes && !isEditing) {
      setSortedAlertTypes(alertTypes)
    }
  }, [alertTypes, isEditing])

  useEffect(() => {
    setOrdinals(
      sortedAlertTypes?.map((alertType: AlertType) => alertType.id),
      alertTypes?.map((alertType: AlertType) => alertType.alertTypeOrdinal.ordinal)
    )
  }, [sortedAlertTypes])

  const onSortEnd = ({oldIndex, newIndex}: OnSortEndParams) => {
    setSortedAlertTypes((prevAlertTypes) => arrayMove(prevAlertTypes, oldIndex, newIndex))
  }
  const firstIndex = limit * (page - 1) + 1

  return (
    <>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th className={styles.numberColumn}>No.</Table.Th>
            <Table.Th className={styles.colorColumn}>カラー</Table.Th>
            <Table.Th className={styles.nameColumn}>アラート名</Table.Th>
            <Table.Th className={styles.explanationColumn}>説明</Table.Th>
            <Table.Th className={styles.baseDateColumn}>通知基準</Table.Th>
            <Table.Th className={styles.adminMailColumn}>メール(管理者)</Table.Th>
            <Table.Th className={styles.employeeMailColumn}>メール(従業員)</Table.Th>
            <Table.Th className={styles.editColumn}></Table.Th>
            <Table.Th className={styles.deleteColumn}></Table.Th>
          </Table.Tr>
        </Table.Thead>

        {alertTypes?.length > 0 ? (
          isEditing ? (
            <SortableAlertTypeTable
              lockAxis="y"
              onSortEnd={onSortEnd}
              helperClass={styles.dragging}
              sortedAlertTypes={sortedAlertTypes}
              refetch={refetch}
            />
          ) : (
            <AlertTypeTable alertTypes={alertTypes} refetch={refetch} firstIndex={firstIndex} />
          )
        ) : (
          <Table.Tbody>
            <Table.Tr>
              <Table.Td colSpan={9}>アラートがまだありません</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        )}
      </Table>
    </>
  )
}
