import {FC, useState, useContext, useRef, ReactNode, createContext, MouseEventHandler} from 'react'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {Close, MenuEllipsis} from 'jbc-front/components/icons'
import styles from './RightExpansionContainer.scss'

type RightExpansionContainerContextType = {
  component: JSX.Element | null
  setComponent: (component: JSX.Element | null) => void
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  key: string | null
  setKey: (key: string | null) => void
  width: number
  setWidth: (width: number) => void
}

const RightExpansionContainerContext = createContext<RightExpansionContainerContextType | null>(null)

type RightExpansionContainerProviderProps = {
  children: ReactNode
}

export const RightExpansionContainerProvider: FC<RightExpansionContainerProviderProps> = ({children}) => {
  const [component, setComponent] = useState<JSX.Element | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [key, setKey] = useState<string | null>(null)
  const [width, setWidth] = useState<number>(400)
  const value = {
    component,
    setComponent,
    isVisible,
    setIsVisible,
    key,
    setKey,
    width,
    setWidth,
  }

  return (
    <RightExpansionContainerContext.Provider value={value}>
      {children}
      <RightExpansionContainer>{component}</RightExpansionContainer>
    </RightExpansionContainerContext.Provider>
  )
}

type RightExpansionContainerProps = {
  children: ReactNode
}

export const RightExpansionContainer: FC<RightExpansionContainerProps> = ({children}) => {
  const {isVisible, setIsVisible, width, setWidth} = useContext(RightExpansionContainerContext) ?? {}

  const nodeRef = useRef(null)

  const handleResizeStart: MouseEventHandler<HTMLDivElement> = (_) => {
    document.addEventListener('mousemove', handleResize)
    document.addEventListener('click', handleResizeEnd)
    document.body.style.cursor = 'col-resize'
    document.body.style.userSelect = 'none'
  }

  const handleResize = (e: MouseEvent) => {
    const newWidth = window.innerWidth - e.clientX
    if (setWidth && newWidth >= 10 && newWidth <= 1600) {
      setWidth(newWidth)
    }
  }

  const handleResizeEnd = () => {
    document.removeEventListener('mousemove', handleResize)
    document.removeEventListener('click', handleResizeEnd)
    document.body.style.cursor = 'default'
    document.body.style.userSelect = 'auto'
  }

  if (!setIsVisible) return null

  return (
    <TransitionGroup>
      {isVisible && (
        <CSSTransition nodeRef={nodeRef} in={isVisible} timeout={300} classNames="containerAnimation">
          <div ref={nodeRef} className={styles.container} style={{width}}>
            <div className={styles.header}>
              <Close className={styles.closeIcon} onClick={() => setIsVisible(false)} />
            </div>
            <div onMouseDown={handleResizeStart} className={styles.resizer}>
              <MenuEllipsis size={15} className={styles.resizerIcon} />
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

export const useRightExpansionContainer = () => {
  const value = useContext(RightExpansionContainerContext)
  if (value === null) throw new Error('valueがありません')

  const onClose = () => {
    value.setIsVisible(false)
  }

  const setContent = async (component: JSX.Element, key: string | null = null, width: number | undefined = 400) => {
    if (value.component === null || value.component === undefined) {
      value.setKey(key)
      value.setComponent(component)
      value.setIsVisible(true)
      value.setWidth(width)
      return
    }

    if (value.key === key) {
      value.setIsVisible(!value.isVisible)
    } else {
      value.setKey(key)
      value.setComponent(component)
      value.setIsVisible(false)
      value.setWidth(width)
      setTimeout(() => {
        value.setIsVisible(true)
      }, 300)
    }
  }

  return {onClose, setContent}
}
