import {FC, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'

import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Label, Required} from 'jbc-front/components/presenters/form/Label'
import {Input} from 'jbc-front/components/presenters/form/Input'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Checkbox} from 'jbc-front/components/presenters/form/Checkbox'
import {ErrorMessage} from 'jbc-front/components/presenters/form/ErrorMessage'
import {Select, Option} from 'jbc-front/components/presenters/form/Select'
import {Radio, RadioContainer} from 'jbc-front/components/presenters/form/Radio'
import Button from 'jbc-front/components/Button'
import {Section} from 'jbc-front/components/Form'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'

import {yupResolver} from '@hookform/resolvers/yup'
import {
  CustomEmployeeFieldGroup,
  AlertTypeInput,
  AlertType,
  convertFieldsToCustomValues,
  ConvertQueryAlertTypeToFormData,
} from '../query'
import {isChangeNotificationDate} from '../mutation'
import {AdminMailBody} from '../../create/parts/AdminMailBody'
import {EmployeeMailBody} from '../../create/parts/EmployeeMailBody'
import {schema} from '../../create/schema'
import {FormField} from 'components/forms/FormField'
import {FormSection} from 'components/forms/FormSection'
import {
  wrapperSectionStyle,
  basicBaseDateTypeSelectOptions,
  colors,
  toColor,
  dateUnitOptionsFilter,
} from '../../create/parts/formUtils'

import styles from '../../create/parts/AlertTypeCreateForm.scss'

type AlertTypeEditFormProps = {
  onSubmit: (data: AlertTypeInput, isDestroy?: boolean) => Promise<void>
  customFields: CustomEmployeeFieldGroup[]
  loading: boolean
  alertType: AlertType | undefined
}

export const AlertTypeEditForm: FC<AlertTypeEditFormProps> = ({onSubmit, loading, customFields, alertType}) => {
  const dispatch = useDispatch()
  const baseDateTypeSelectOptions: Option[] = [
    ...basicBaseDateTypeSelectOptions,
    ...convertFieldsToCustomValues(customFields),
  ]
  const defaultValues = alertType ? ConvertQueryAlertTypeToFormData(alertType, baseDateTypeSelectOptions) : undefined

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    trigger,
    formState: {errors, isValid},
  } = useForm<AlertTypeInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })
  const selectedBaseDateType = watch('baseDateType')
  const adminMailActive = watch('adminMailActive')
  const employeeMailActive = watch('employeeMailActive')

  const filteredDateUnitOptions = dateUnitOptionsFilter(selectedBaseDateType.value)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleCloseModal = () => setIsModalOpen(false)
  const handleOpenModal = () => setIsModalOpen(true)

  const handleSave = (data: AlertTypeInput) => {
    if (isChangeNotificationDate(defaultValues, data)) {
      handleOpenModal()
    } else {
      onSubmit(data)
    }
  }

  const handleModalSave = async () => {
    await onSubmit(getValues(), true)
    setIsModalOpen(false)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)} className={styles.FormAlertCustomization}>
        <Section title="アラート設定" style={wrapperSectionStyle}>
          <FormSection>
            <FormField>
              <Label>アラートカラー</Label>
              <Spacer direction="y" size={8} />
              <RadioContainer className={styles.radioColorContainer}>
                {colors.map((color) => (
                  <Radio key={color} {...register('color')} value={color} defaultChecked={watch('color') === color}>
                    <span className={styles.colorOption} style={{backgroundColor: toColor(color)}}></span>
                  </Radio>
                ))}
              </RadioContainer>
            </FormField>
            <FormField>
              <FormField.LabelContainer>
                <Label>
                  アラート名
                  <Spacer direction="x" size={8} />
                  <Required />
                </Label>
              </FormField.LabelContainer>
              <Input {...register('name')} />
              <FormField.ErrorContainer>
                {errors.name?.message && <ErrorMessage>{errors.name.message}</ErrorMessage>}
              </FormField.ErrorContainer>
            </FormField>

            <FormField>
              <FormField.LabelContainer>
                <Label>説明</Label>
              </FormField.LabelContainer>
              <Textarea rows={4} {...register('description')} />
              <FormField.ErrorContainer>
                {errors.description?.message && <ErrorMessage>{errors.description.message}</ErrorMessage>}
              </FormField.ErrorContainer>
            </FormField>

            <Section
              title={
                <>
                  通知基準 <Required />
                </>
              }
              style={wrapperSectionStyle}
            >
              <div className={styles.baseDateContainer}>
                <div className={styles.baseDateSelect}>
                  <Controller
                    name="baseDateType"
                    control={control}
                    render={({field}) => (
                      <Select
                        options={baseDateTypeSelectOptions}
                        value={field.value}
                        onChange={(val: Option) => {
                          field.onChange(val)
                          trigger('offset')
                          trigger('days')
                          trigger('dateUnit')
                        }}
                        noOptionsMessage={() => 'データが見つかりません'}
                      />
                    )}
                  />
                </div>
                {['age', 'service_years'].includes(selectedBaseDateType.value) && (
                  <div>
                    <Input {...register('offset')} />
                    <span>
                      {selectedBaseDateType.value === 'age'
                        ? '歳'
                        : selectedBaseDateType.value === 'service_years'
                        ? '年'
                        : ''}
                    </span>
                  </div>
                )}

                <div>
                  <Input className={styles.inputDays} {...register('days')} />

                  <div className={styles.dateUnitField}>
                    <Controller
                      name="dateUnit"
                      control={control}
                      render={({field}) => (
                        <Select
                          options={filteredDateUnitOptions}
                          value={filteredDateUnitOptions?.find((option: Option) => option.value === field.value)}
                          onChange={(val: Option) => {
                            field.onChange(val.value)
                            trigger('days')
                          }}
                          noOptionsMessage={() => 'データが見つかりません'}
                        />
                      )}
                    />
                  </div>

                  <RadioContainer className={styles.radioContainer}>
                    <Radio {...register('isBefore')} value="Before">
                      前
                    </Radio>
                    <Radio {...register('isBefore')} value="After">
                      後
                    </Radio>
                  </RadioContainer>
                </div>
              </div>
              {errors.offset?.message && <ErrorMessage>{errors.offset.message}</ErrorMessage>}
              {errors.days?.message && <ErrorMessage>{errors.days.message}</ErrorMessage>}
              {errors.dateUnit?.message && <ErrorMessage>{errors.dateUnit.message}</ErrorMessage>}
            </Section>

            <div className={styles.checkBoxList}>
              <Checkbox {...register('adminMailActive')}>管理者にメール通知</Checkbox>
              <Checkbox {...register('employeeMailActive')}>従業員にメール通知</Checkbox>
              <Checkbox {...register('active')}>有効にする</Checkbox>
            </div>
          </FormSection>
        </Section>

        {adminMailActive && (
          <Section title="メール設定（管理者）" style={wrapperSectionStyle}>
            <FormSection>
              <FormField>
                <FormField.LabelContainer>
                  <Label>
                    件名
                    <Spacer direction="x" size={8} />
                    <Required />
                  </Label>
                </FormField.LabelContainer>
                <Input {...register('adminMailSubject')} />
                <FormField.ErrorContainer>
                  {errors.adminMailSubject?.message && <ErrorMessage>{errors.adminMailSubject.message}</ErrorMessage>}
                </FormField.ErrorContainer>
              </FormField>

              <FormField>
                <FormField.LabelContainer>
                  <Label>
                    本文
                    <Spacer direction="x" size={8} />
                    <Required />
                  </Label>
                </FormField.LabelContainer>
                <FormField.ErrorContainer>
                  {errors.adminMailBody?.message && <ErrorMessage>{errors.adminMailBody.message}</ErrorMessage>}
                </FormField.ErrorContainer>
                <AdminMailBody name="adminMailBody" control={control} />
              </FormField>
            </FormSection>
          </Section>
        )}

        {employeeMailActive && (
          <Section title="メール設定（従業員）" style={wrapperSectionStyle}>
            <FormSection>
              <FormField>
                <FormField.LabelContainer>
                  <Label>
                    件名
                    <Spacer direction="x" size={8} />
                    <Required />
                  </Label>
                </FormField.LabelContainer>
                <Input {...register('employeeMailSubject')} />
                <FormField.ErrorContainer>
                  {errors.employeeMailSubject?.message && (
                    <ErrorMessage>{errors.employeeMailSubject.message}</ErrorMessage>
                  )}
                </FormField.ErrorContainer>
              </FormField>

              <FormField>
                <FormField.LabelContainer>
                  <Label>
                    本文
                    <Spacer direction="x" size={8} />
                    <Required />
                  </Label>
                </FormField.LabelContainer>
                <FormField.ErrorContainer>
                  {errors.employeeMailBody?.message && <ErrorMessage>{errors.employeeMailBody.message}</ErrorMessage>}
                </FormField.ErrorContainer>
                <EmployeeMailBody name="employeeMailBody" control={control} />
              </FormField>
            </FormSection>
          </Section>
        )}

        <div className={styles.bottomButtonsArea}>
          <Button onClick={() => dispatch(push('/settings/alert_custom'))} disabled={loading}>
            キャンセル
          </Button>
          <Button onClick={handleSubmit(handleSave)} primary disabled={loading || !isValid}>
            保存
          </Button>
        </div>
      </form>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Modal.Header onClose={handleCloseModal}>変更の確認</Modal.Header>
        <Modal.Body>
          通知基準の設定を変更すると、変更前のアラートが削除され、変更後の内容でアラートが再設定されます。
          <br />
          <span className={styles.attention}>設定を変更しますか？</span>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleCloseModal}>いいえ</Button>
            <Button primary onClick={handleModalSave} disabled={loading}>
              はい
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
